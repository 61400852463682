import { useRecoilValue, useSetRecoilState } from 'recoil'
import { userState } from '../../../states/UserState'
import { useNavigate } from 'react-router'
import { useEffect, useState } from 'react'
import { scenarioSnapshotState } from '../../../states/ScenarioSnapshotState'
import InputText from '../../../Components/Inputs/InputText/InputText'
import Button from '../../../Components/Buttons/Button/Button'
import { getSplitText, getText } from '../../../services/textFunctions'
import exitImg from '../../../resources/images/187-newExitImg.svg'
import { scenarioIdentityState } from '../../../states/ScenarioIdentityState'
import { getScaleForPopUp } from '../../../services/popUpPositionFunctions'
import useWindowSize from '../../../customHooks/useWindowSize'
import { allowShortcutsState } from '../../../states/AllowShortcutsState'
import { getClientDomain, isDevEnv } from '../../../services/commonFunctions'
import englishTexts from '../../../resources/texts/englishTexts.json'
import { AnimationDots } from '../../../Components/Animations/AnimationDots'
import { ANIMATION_DOTS_SPEED } from '../../../services/constants'
import { AvailableLanguages } from '../../../models/enums'

type Props = {
  showPopUp: string
  setShowPopUp: (value: string) => void
  handleSaveAsNewScenario: (
    newScenarioName: string,
    recerse?: boolean,
  ) => Promise<string>
}

const MoreOptionsPopUp = (props: Props) => {
  const user = useRecoilValue(userState)
  const scenarioIdentity = useRecoilValue(scenarioIdentityState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const setAllowShortcuts = useSetRecoilState(allowShortcutsState)

  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]
  const navigate = useNavigate()
  const { width, height } = useWindowSize()

  const [scenarioTitle, setScenarioTitle] = useState('')
  const [scenarioExists, setScenarioExists] = useState(false)
  const [scenarioNoLength, setScenarioNoLength] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (props.showPopUp === 'newScenario') {
      const newName = `${scenarioIdentity.scenarioName} (Copy)`
      setScenarioTitle(
        newName.length < 172 ? newName : `${scenarioIdentity.scenarioName}(2)`,
      )
    } else if (props.showPopUp === 'otherView') {
      //data-textattribute="title-231"
      const newName = `${scenarioIdentity.scenarioName} ${getText(
        'title-231',
        user.settings,
        partiesFormat,
      )}`

      setScenarioTitle(
        newName.length < 172
          ? newName
          : getSplitText(
              newName,
              getText('title-231', user.settings, partiesFormat),
              140,
              60,
            ),
      )
    }

    // eslint-disable-next-line
  }, [props.showPopUp])

  const closePopUp = () => {
    props.setShowPopUp('')
    setAllowShortcuts(true)
  }

  const clickOnEmptySpace = (e: any) => {
    if (e.target.id === 'moreBackgroundPopUp') {
      closePopUp()
    }
  }

  const onSaveAsInputKeyPress = (e: any) => {
    /* Save as a new Scenario Button pressed on Enter */

    if (e.key === 'Enter' && props.showPopUp !== '') {
      onSaveNewScenario()
    }
  }

  const onSaveNewScenario = async () => {
    if (scenarioTitle.trim().length === 0) {
      setScenarioNoLength(true)
    } else {
      setLoading(true)
      const res = await props.handleSaveAsNewScenario(
        scenarioTitle,
        props.showPopUp === 'otherView',
      )
      setTimeout(() => {
        setLoading(false)

        if (res === 'exists') {
          setScenarioExists(true)
        } else if (res !== 'error') {
          closePopUp()

          if (props.showPopUp === 'otherView') {
            window.open(
              `${isDevEnv() ? 'http' : 'https'}://${getClientDomain()}/#${res}`,
              '_blank',
              'noreferrer',
            )
          } else {
            navigate(res)
          }
        }
      }, 500)
    }
  }

  return (
    <div
      id="moreBackgroundPopUp"
      className={`moreBackgroundPopUp`}
      onMouseDown={clickOnEmptySpace}
    >
      {loading ? (
        <div
          className={`morePopUp loading`}
          style={{
            transform: `scale(${getScaleForPopUp(width, height, 485, 280)})`,
          }}
        >
          <p
            className="loadingMessage"
            data-textattribute={
              props.showPopUp === 'otherView' ? 'message-111' : 'message-56'
            }
          >
            {props.showPopUp === 'otherView' ? (
              <>
                {getText('message-111', user.settings, partiesFormat)}
                <AnimationDots speed={ANIMATION_DOTS_SPEED} />
              </>
            ) : (
              getText('message-56', user.settings)
            )}
          </p>
        </div>
      ) : (
        <div
          className={`morePopUp ${
            props.showPopUp === 'otherView' ? 'withSubtitle' : ''
          }`}
          style={{
            transform: `scale(${getScaleForPopUp(
              width,
              height,
              680,
              props.showPopUp === 'otherView' ? 400 : 280,
            )})`,
          }}
        >
          <div
            className="title"
            id="saveAsNewScenarioTitle"
            data-textattribute={
              props.showPopUp === 'newScenario' ? 'submenu-20' : 'title-230'
            }
          >
            {props.showPopUp === 'newScenario'
              ? getText('submenu-20', user.settings)
              : getSplitText(
                  getText('title-230', user.settings, partiesFormat),
                  user.settings.language === AvailableLanguages.english
                    ? englishTexts['title-230'].substring(14)
                    : user.settings.language === AvailableLanguages.norwegian
                    ? 's perspektiv (BETA)'
                    : 's perspektiv (BETA)',
                  40,
                  22,
                )}
          </div>
          {props.showPopUp === 'otherView' && (
            <div
              className="subTitle"
              data-textattribute="description-186, description-187"
            >
              {getText('description-186', user.settings, partiesFormat)}
              <br />
              <br />
              {getText('description-187', user.settings)}
            </div>
          )}
          <div
            className={`inputContainer ${
              props.showPopUp === 'otherView' ? 'withSubtitle' : ''
            }`}
          >
            <InputText
              label={getText('label-87', user.settings)}
              labelTextAttribute="label-87"
              id="scenarioTitle"
              width={620}
              onChange={(e: any) => {
                setScenarioNoLength(false)
                setScenarioExists(false)
                setScenarioTitle(e.target.value)
              }}
              value={scenarioTitle}
              name="scenarioTitle"
              error={scenarioNoLength || scenarioExists}
              errorMessage={
                scenarioExists
                  ? getText('error-91', user.settings)
                  : getText('error-90', user.settings)
              }
              errorTextAttribute={scenarioExists ? 'error-91' : 'error-90'}
              maxLength={140}
              tabIndex={1}
              onKeyDown={onSaveAsInputKeyPress}
              alwaysShrink
              noDelay
            />
          </div>
          <div className="buttonsContainer">
            <Button
              buttonText={getText('button-69', user.settings)}
              buttonTextAttribute={'button-69'}
              id="cancelButton"
              type="button"
              buttonType="text"
              onClick={closePopUp}
              side="left"
              NoUpperCase={true}
              small={false}
            />
            <Button
              buttonType="contained"
              buttonText={getText(
                props.showPopUp === 'newScenario' ? 'button-68' : 'button-12',
                user.settings,
              )}
              buttonTextAttribute={
                props.showPopUp === 'newScenario' ? 'button-68' : 'button-12'
              }
              id="saveAsNewScenarioButton"
              type="button"
              onClick={onSaveNewScenario}
              side="left"
              NoUpperCase={true}
              small={false}
            />
          </div>
          <div onClick={closePopUp} className="exitButtonImgContainer">
            <img src={exitImg} alt="exitImg" className="exitButtonImg" />
          </div>
        </div>
      )}
    </div>
  )
}

export default MoreOptionsPopUp
