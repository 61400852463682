import {
  AvailableLanguages,
  LegalCostsParty,
  LegalCostsProceedings,
  TypeOfInstance,
} from '../../../../../../../../models/enums'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import {
  ReportLegalCostsType,
  ReportWhitelabel,
} from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { ScenarioSnapshot } from '../../../../../../../../models/scenarioSnapshot'
import { User } from '../../../../../../../../models/user'
import {
  getLegalCosts,
  legalFeesZero,
} from '../../../../../../../../services/legalCostsFunctions'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import { getLegalCostsSingleValue } from '../../../../../../../../services/resultsFunctions'
import {
  getSplitText,
  getText,
} from '../../../../../../../../services/textFunctions'
import { Text, View, StyleSheet } from '@react-pdf/renderer'

type Props = {
  currentSnapshot: ScenarioSnapshot
  user: User
  legalCostsDescriptions: ReportLegalCostsType
  reportWhitelabel?: ReportWhitelabel
  forLegalCostsComponent?: boolean
}

export default function LegalCostsPreviewComponentDocument(props: Props) {
  const partiesFormat = [
    props.currentSnapshot.partyFormatOwn,
    props.currentSnapshot.partyFormatOther,
  ]

  const hasSecondTrial = props.currentSnapshot.probSecondTrial! > 0

  const firstProceedingsDefaultMessage = hasSecondTrial
    ? getText('title-129', props.user.settings)
    : getText('title-130', props.user.settings)

  const secondProceedingsDefaultMessage =
    props.currentSnapshot.court === TypeOfInstance.Public_Court
      ? //title-131a
        getText('title-131a', props.user.settings)
      : //title-131b
        getText('title-131b', props.user.settings)

  const firstProceedingsText =
    props.legalCostsDescriptions.firstOwnLegalCostsDescription.length > 0
      ? props.legalCostsDescriptions.firstOwnLegalCostsDescription
      : ''
  const secondProceedingsText =
    props.legalCostsDescriptions.secondOwnLegalCostsDescription.length > 0
      ? props.legalCostsDescriptions.secondOwnLegalCostsDescription
      : ''

  const legalCostsObj = props.currentSnapshot.legalCosts

  const styles = StyleSheet.create({
    paragraph: {
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        'noRoboto',
        true,
      ).color,
      maxWidth: '450px',
      fontSize: '8px',
    },
    partiesHeader: {
      width: props.forLegalCostsComponent ? '450px' : '360px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginTop: props.forLegalCostsComponent ? '14px' : '0px',
      marginBottom: '2px',
    },
    proccedingsContainer: {
      width: props.forLegalCostsComponent ? '450px' : '360px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginBottom: '10px',
    },
    firstColumn: {
      width: '36%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    secondColumn: {
      width: '32%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    thirdColumn: {
      width: '32%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    firstRow: { marginBottom: '3px' },
    valueText: {
      width: '90px',
      marginRight: '40px',
      textAlign: 'right',
    },
    normalText: {
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        'noRoboto',
        true,
      ).color,
      textOverflow: 'ellipsis',
      marginTop: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.none,
        'noRoboto',
        true,
      ).marginTop,
      maxLines: 1,
    },
    normalTextRoboto: {
      fontSize: '7.5px',
      fontFamily: 'Roboto-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        'roboto',
        true,
      ).color,
      textOverflow: 'ellipsis',
      marginTop: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        'roboto',
        true,
      ).marginTop,
      maxLines: 1,
    },
    semiBoldText: {
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.medium,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Medium',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.medium,
        'noRoboto',
        true,
      ).color,
      textOverflow: 'ellipsis',
      maxLines: 1,
    },
    boldText: {
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.medium,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Medium',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.medium,
        'noRoboto',
        true,
      ).color,
      textOverflow: 'ellipsis',
      maxLines: 1,
    },
    boldTextRoboto: {
      fontSize: '7.5px',
      fontFamily: 'Roboto-Medium',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.bold,
        'roboto',
        true,
      ).color,
      textOverflow: 'ellipsis',
      maxLines: 1,
    },
  })

  return (
    <View wrap={false} style={{ width: '450px' }}>
      {legalFeesZero(props.currentSnapshot) ? (
        <Text
          style={styles.paragraph}
          id="noLegalsCostsParagraphForPdf"
          data-textaatribute="description-52"
        >
          {getText('description-52', props.user.settings)}
        </Text>
      ) : (
        <>
          <View style={styles.partiesHeader}>
            <View style={styles.firstColumn}></View>
            <View style={styles.secondColumn}>
              <Text
                style={
                  props.forLegalCostsComponent
                    ? {
                        ...styles.boldText,
                        ...styles.valueText,
                      }
                    : {
                        ...styles.normalText,
                        ...styles.valueText,
                      }
                }
              >
                {getText('title-97', props.user.settings, partiesFormat)}
              </Text>
            </View>
            <View style={styles.thirdColumn}>
              <Text
                style={
                  props.forLegalCostsComponent
                    ? {
                        ...styles.boldText,
                        ...styles.valueText,
                      }
                    : {
                        ...styles.normalText,
                        ...styles.valueText,
                      }
                }
              >
                {getText('title-98', props.user.settings, partiesFormat)}
              </Text>
            </View>
          </View>
          <View style={styles.proccedingsContainer}>
            <View style={styles.firstColumn}>
              <Text
                style={{
                  ...styles.semiBoldText,
                  ...styles.firstRow,
                }}
              >
                {firstProceedingsText.length > 0
                  ? firstProceedingsText
                  : firstProceedingsDefaultMessage}
                :
              </Text>
              <Text
                style={{
                  ...styles.normalText,
                  marginLeft: '8px',
                }}
              >
                {getText('label-89', props.user.settings)}:
              </Text>
              <Text
                style={{
                  ...styles.normalText,
                  marginLeft: '8px',
                }}
              >
                {getText('label-91', props.user.settings)}:
              </Text>
            </View>
            <View style={styles.secondColumn}>
              <Text
                style={{
                  ...styles.boldTextRoboto,
                  ...styles.firstRow,
                  ...styles.valueText,
                }}
              >
                {getLegalCostsSingleValue(
                  legalCostsObj.clientLegalCosts.firstProceedings
                    .estimatedCosts!,
                  props.currentSnapshot.currency,
                  props.user.settings,
                )}
              </Text>
              <Text style={{ ...styles.normalTextRoboto, ...styles.valueText }}>
                {getLegalCostsSingleValue(
                  getLegalCosts(
                    'recoverableCosts',
                    LegalCostsParty.clientLegalCosts,
                    LegalCostsProceedings.firstProceedings,
                    props.currentSnapshot,
                    props.user.settings,
                  ),
                  props.currentSnapshot.currency,
                  props.user.settings,
                )}
              </Text>
              <Text style={{ ...styles.normalTextRoboto, ...styles.valueText }}>
                {getLegalCostsSingleValue(
                  getLegalCosts(
                    'incurredCosts',
                    LegalCostsParty.clientLegalCosts,
                    LegalCostsProceedings.firstProceedings,
                    props.currentSnapshot,
                    props.user.settings,
                  ),
                  props.currentSnapshot.currency,
                  props.user.settings,
                )}
              </Text>
            </View>
            <View style={styles.thirdColumn}>
              <Text
                style={{
                  ...styles.boldTextRoboto,
                  ...styles.firstRow,
                  ...styles.valueText,
                }}
              >
                {getLegalCostsSingleValue(
                  legalCostsObj.opposingPartyLegalCosts.firstProceedings
                    .estimatedCosts!,
                  props.currentSnapshot.currency,
                  props.user.settings,
                )}
              </Text>
              <Text style={{ ...styles.normalTextRoboto, ...styles.valueText }}>
                {getLegalCostsSingleValue(
                  getLegalCosts(
                    'recoverableCosts',
                    LegalCostsParty.opposingPartyLegalCosts,
                    LegalCostsProceedings.firstProceedings,
                    props.currentSnapshot,
                    props.user.settings,
                  ),
                  props.currentSnapshot.currency,
                  props.user.settings,
                )}
              </Text>
              <Text style={{ ...styles.normalTextRoboto, ...styles.valueText }}>
                {getLegalCostsSingleValue(
                  getLegalCosts(
                    'incurredCosts',
                    LegalCostsParty.opposingPartyLegalCosts,
                    LegalCostsProceedings.firstProceedings,
                    props.currentSnapshot,
                    props.user.settings,
                  ),
                  props.currentSnapshot.currency,
                  props.user.settings,
                )}
              </Text>
            </View>
          </View>
          {hasSecondTrial && (
            <View style={styles.proccedingsContainer}>
              <View style={styles.firstColumn}>
                <Text
                  style={{
                    ...styles.semiBoldText,
                    ...styles.firstRow,
                  }}
                >
                  {secondProceedingsText.length > 0
                    ? secondProceedingsText
                    : secondProceedingsDefaultMessage}
                  :
                </Text>
                <Text
                  style={{
                    ...styles.normalText,
                    marginLeft: '8px',
                  }}
                >
                  {getText('label-89', props.user.settings)}:
                </Text>
                <Text
                  style={{
                    ...styles.normalText,
                    marginLeft: '8px',
                  }}
                >
                  {getText('label-91', props.user.settings)}:
                </Text>
              </View>
              <View style={styles.secondColumn}>
                <Text
                  style={{
                    ...styles.boldTextRoboto,
                    ...styles.firstRow,
                    ...styles.valueText,
                  }}
                >
                  {getLegalCostsSingleValue(
                    legalCostsObj.clientLegalCosts.additionalProceedings!
                      .estimatedCosts!,
                    props.currentSnapshot.currency,
                    props.user.settings,
                  )}
                </Text>
                <Text
                  style={{ ...styles.normalTextRoboto, ...styles.valueText }}
                >
                  {getLegalCostsSingleValue(
                    getLegalCosts(
                      'recoverableCosts',
                      LegalCostsParty.clientLegalCosts,
                      LegalCostsProceedings.additionalProceedings,
                      props.currentSnapshot,
                      props.user.settings,
                    ),
                    props.currentSnapshot.currency,
                    props.user.settings,
                  )}
                </Text>
                <Text
                  style={{ ...styles.normalTextRoboto, ...styles.valueText }}
                >
                  {getLegalCostsSingleValue(
                    getLegalCosts(
                      'incurredCosts',
                      LegalCostsParty.clientLegalCosts,
                      LegalCostsProceedings.additionalProceedings,
                      props.currentSnapshot,
                      props.user.settings,
                    ),
                    props.currentSnapshot.currency,
                    props.user.settings,
                  )}
                </Text>
              </View>
              <View style={styles.thirdColumn}>
                <Text
                  style={{
                    ...styles.boldTextRoboto,
                    ...styles.firstRow,
                    ...styles.valueText,
                  }}
                >
                  {getLegalCostsSingleValue(
                    legalCostsObj.opposingPartyLegalCosts.additionalProceedings!
                      .estimatedCosts!,
                    props.currentSnapshot.currency,
                    props.user.settings,
                  )}
                </Text>
                <Text
                  style={{ ...styles.normalTextRoboto, ...styles.valueText }}
                >
                  {getLegalCostsSingleValue(
                    getLegalCosts(
                      'recoverableCosts',
                      LegalCostsParty.opposingPartyLegalCosts,
                      LegalCostsProceedings.additionalProceedings,
                      props.currentSnapshot,
                      props.user.settings,
                    ),
                    props.currentSnapshot.currency,
                    props.user.settings,
                  )}
                </Text>
                <Text
                  style={{ ...styles.normalTextRoboto, ...styles.valueText }}
                >
                  {getLegalCostsSingleValue(
                    getLegalCosts(
                      'incurredCosts',
                      LegalCostsParty.opposingPartyLegalCosts,
                      LegalCostsProceedings.additionalProceedings,
                      props.currentSnapshot,
                      props.user.settings,
                    ),
                    props.currentSnapshot.currency,
                    props.user.settings,
                  )}
                </Text>
              </View>
            </View>
          )}
          {props.forLegalCostsComponent &&
            props.currentSnapshot.hasInsurance && (
              <View style={styles.proccedingsContainer}>
                <View style={styles.firstColumn}>
                  <Text
                    style={{
                      ...styles.semiBoldText,
                      ...styles.firstRow,
                    }}
                    data-textattribute="title-254"
                  >
                    {getSplitText(
                      `${getText(
                        'title-97',
                        props.user.settings,
                        partiesFormat,
                      )}${getText('title-254', props.user.settings)}`,
                      props.user.settings.language ===
                        AvailableLanguages.norwegian
                        ? 's forsikring for sakskostnader'
                        : props.user.settings.language ===
                          AvailableLanguages.swedish
                        ? 's rättsskyddsförsäkring'
                        : '’s',
                      40,
                      10,
                    )}
                  </Text>
                  <Text
                    style={{
                      ...styles.normalText,
                      marginLeft: '8px',
                    }}
                  >
                    {getText('label-36', props.user.settings)}:
                  </Text>
                  <Text
                    style={{
                      ...styles.normalText,
                      marginLeft: '8px',
                    }}
                    data-textattribute="title-255"
                  >
                    {getText('title-255', props.user.settings)}:
                  </Text>
                  <Text
                    style={{
                      ...styles.normalText,
                      marginLeft: '8px',
                    }}
                    data-textattribute="title-256"
                  >
                    {getText('title-256', props.user.settings)}:
                  </Text>
                </View>
                <View style={styles.secondColumn}>
                  <Text
                    style={{
                      ...styles.boldText,
                      color: 'transparent',
                      ...styles.firstRow,
                      ...styles.valueText,
                    }}
                  >
                    {'No Value'}
                  </Text>
                  <Text
                    style={{ ...styles.normalTextRoboto, ...styles.valueText }}
                  >
                    {getLegalCostsSingleValue(
                      props.currentSnapshot.minimumExcessFee!,
                      props.currentSnapshot.currency,
                      props.user.settings,
                    )}
                  </Text>
                  <Text
                    style={{
                      ...styles.normalTextRoboto,
                      ...styles.valueText,
                    }}
                  >
                    {getLegalCostsSingleValue(
                      props.currentSnapshot.insuranceExcessPercentage!,
                      props.currentSnapshot.currency,
                      props.user.settings,
                      true,
                    )}
                  </Text>
                  <Text
                    style={{ ...styles.normalTextRoboto, ...styles.valueText }}
                  >
                    {getLegalCostsSingleValue(
                      props.currentSnapshot.maxInsurance!,
                      props.currentSnapshot.currency,
                      props.user.settings,
                    )}
                  </Text>
                </View>
                <View style={styles.thirdColumn}>
                  <Text
                    style={{
                      ...styles.boldText,
                      color: 'transparent',
                      ...styles.firstRow,
                      ...styles.valueText,
                    }}
                  >
                    {'No Value'}
                  </Text>
                  <Text
                    style={{
                      ...styles.normalText,
                      color: 'transparent',
                      ...styles.valueText,
                    }}
                  >
                    {'No Value'}
                  </Text>
                  <Text
                    style={{
                      ...styles.normalText,
                      color: 'transparent',
                      ...styles.valueText,
                    }}
                  >
                    {'No Value'}
                  </Text>
                  <Text
                    style={{
                      ...styles.normalText,
                      color: 'transparent',
                      ...styles.valueText,
                    }}
                  >
                    {'No Value'}
                  </Text>
                </View>
              </View>
            )}
        </>
      )}
    </View>
  )
}
