import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  AvailableLanguages,
  TypeOfInstance,
} from '../../../../../../../../models/enums'
import {
  deepCloneObject,
  logActivity,
} from '../../../../../../../../services/commonFunctions'
import { reportState } from '../../../../../../../../states/ReportState'
import editButtonImg from '../../../../../../../../resources/images/027-renameSign.svg'
import {
  getText,
  translateDate,
} from '../../../../../../../../services/textFunctions'
import { userState } from '../../../../../../../../states/UserState'
import {
  ReportProceedingsType,
  ReportWhitelabel,
} from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import { freemiumState } from '../../../../../../../../states/FreemiumState'

type Props = {
  defaultInstance: TypeOfInstance
  trial: 'first' | 'second'
  date: string
  proceedings: ReportProceedingsType
  reportWhitelabel?: ReportWhitelabel
}

export default function DateOfTrialComponent(props: Props) {
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)

  const [report, setReport] = useRecoilState(reportState)

  const typesOfTrialTitles = {
    //id='reportDateOfTrial'
    first: {
      //data-textattribute='title-121'
      Arbitration: getText('title-121', user.settings),
      //data-textattribute='title-122'
      Public_Court: getText('title-122', user.settings),
      //data-textattribute='title-123'
      Other: getText('title-123', user.settings),
    },
    second: {
      //data-textattribute='title-124'
      Arbitration: getText('title-124', user.settings),
      //data-textattribute='title-124'
      Public_Court: getText('title-124', user.settings),
      //data-textattribute='title-125'
      Other: getText('title-125', user.settings),
    },
  }
  //id='reportEstimatedDate'
  //data-textattribute='title-126'
  const introText = getText('title-126', user.settings)

  const introTextFirst =
    props.proceedings.firstCourtDateDescription &&
    props.proceedings.firstCourtDateDescription.length > 0
      ? props.proceedings.firstCourtDateDescription
      : ''
  const introTextSecond =
    props.proceedings.secondCourtDateDescription &&
    props.proceedings.secondCourtDateDescription.length > 0
      ? props.proceedings.secondCourtDateDescription
      : ''

  const trialTitleFirst =
    props.proceedings.firstCourtName &&
    props.proceedings.firstCourtName.length > 0
      ? props.proceedings.firstCourtName
      : ''
  const trialTitleSecond =
    props.proceedings.secondCourtName &&
    props.proceedings.secondCourtName.length > 0
      ? props.proceedings.secondCourtName
      : ''
  const [courtEditMode, setCourtEditMode] = useState(false)
  const [showCourtEditButton, setShowCourtEditButton] = useState(false)
  const [isCourtEmpty, setIsCourtEmpty] = useState(false)
  const [editableCourtText, setEditableCourtText] = useState(
    props.trial === 'first' ? trialTitleFirst : trialTitleSecond,
  )

  const [dateTextEditMode, setDateTextEditMode] = useState(false)
  const [showDateTextEditButton, setShowDateTextEditButton] = useState(false)
  const [isDateTextEmpty, setIsDateTextEmpty] = useState(false)
  const [editableDateText, setEditableDateText] = useState(
    props.trial === 'first' ? introTextFirst : introTextSecond,
  )

  useEffect(() => {
    if (courtEditMode) {
      setDateTextEditMode(false)
      setTimeout(() => {
        if (
          document.getElementById(
            `instanceInput-dateOftrialComponent-${props.trial}`,
          )
        ) {
          document
            .getElementById(
              `instanceInput-dateOftrialComponent-${props.trial}`,
            )!
            .focus()
        }
      }, 20)
    }
    if (dateTextEditMode) {
      setCourtEditMode(false)
      setTimeout(() => {
        if (
          document.getElementById(
            `dateOfTrialIntroTextInput-dateOftrialComponent-${props.trial}`,
          )
        ) {
          document
            .getElementById(
              `dateOfTrialIntroTextInput-dateOftrialComponent-${props.trial}`,
            )!
            .focus()
        }
      }, 20)
    }
    // eslint-disable-next-line
  }, [courtEditMode, dateTextEditMode])

  useEffect(() => {
    setIsCourtEmpty(editableCourtText.length === 0)
  }, [editableCourtText])

  useEffect(() => {
    setIsDateTextEmpty(editableDateText.length === 0)
  }, [editableDateText])

  useEffect(() => {
    window.addEventListener('mousedown', mouseDownLeaveEditMode)

    return () => {
      window.removeEventListener('mousedown', mouseDownLeaveEditMode)
    }
    // eslint-disable-next-line
  }, [editableCourtText, courtEditMode, dateTextEditMode, editableDateText])

  function mouseDownLeaveEditMode(e: any) {
    if (courtEditMode && !e.target.id.includes(`instanceInput`)) {
      setCourtEditMode(false)
    }
    if (
      dateTextEditMode &&
      !e.target.id.includes(`dateOfTrialIntroTextInput`)
    ) {
      setDateTextEditMode(false)
    }
  }

  useEffect(() => {
    const handle = setTimeout(() => {
      let tempReport = deepCloneObject(report)
      let proceedingsIndex = report.reportData.sections.findIndex(
        (data) => data.contents.proceedings !== undefined,
      )
      if (props.trial === 'first') {
        if (
          tempReport.reportData.sections[proceedingsIndex].contents.proceedings!
            .firstCourtName !== editableCourtText
        ) {
          //Mixpanel 153 (All)
          logActivity(
            freemium.isFreemium,
            'Renamed the court in the proceedings in the report',
          )
        }
        tempReport.reportData.sections[
          proceedingsIndex
        ].contents.proceedings!.firstCourtName = editableCourtText
      } else {
        if (
          tempReport.reportData.sections[proceedingsIndex].contents.proceedings!
            .secondCourtName !== editableCourtText
        ) {
          //Mixpanel 153
          logActivity(
            false,
            'Renamed the court in the proceedings in the report',
          )
        }
        tempReport.reportData.sections[
          proceedingsIndex
        ].contents.proceedings!.secondCourtName = editableCourtText
      }
      setReport(tempReport)
    }, 300)

    return () => clearTimeout(handle)
    // eslint-disable-next-line
  }, [editableCourtText, courtEditMode])

  useEffect(() => {
    const handle = setTimeout(() => {
      let tempReport = deepCloneObject(report)
      let proceedingsIndex = report.reportData.sections.findIndex(
        (data) => data.contents.proceedings !== undefined,
      )
      if (props.trial === 'first') {
        if (
          tempReport.reportData.sections[proceedingsIndex].contents.proceedings!
            .firstCourtDateDescription !== editableDateText
        ) {
          //Mixpanel 154 (All)
          logActivity(
            freemium.isFreemium,
            'Renamed the estimated date in the proceedings in the report',
          )
        }
        tempReport.reportData.sections[
          proceedingsIndex
        ].contents.proceedings!.firstCourtDateDescription = editableDateText
      } else {
        if (
          tempReport.reportData.sections[proceedingsIndex].contents.proceedings!
            .secondCourtDateDescription !== editableDateText
        ) {
          //Mixpanel 154
          logActivity(
            false,
            'Renamed the estimated date in the proceedings in the report',
          )
        }
        tempReport.reportData.sections[
          proceedingsIndex
        ].contents.proceedings!.secondCourtDateDescription = editableDateText
      }

      setReport(tempReport)
    }, 300)

    return () => clearTimeout(handle)
    // eslint-disable-next-line
  }, [editableDateText, dateTextEditMode])

  return (
    <div className="dateOfTrialContainer">
      <div
        className="instanceAndDateContainer"
        onMouseEnter={
          courtEditMode ? undefined : () => setShowCourtEditButton(true)
        }
        onMouseLeave={() => setShowCourtEditButton(false)}
      >
        {showCourtEditButton ? (
          <div
            className="editButtonContainer"
            onClick={() => {
              setCourtEditMode(true)
              setShowCourtEditButton(false)
            }}
            style={
              user.settings.language === AvailableLanguages.norwegian ||
              user.settings.language === AvailableLanguages.swedish
                ? { marginTop: '5px', marginLeft: '-10px', fontSize: '14px' }
                : { marginTop: '5px' }
            }
          >
            <img src={editButtonImg} alt="" className="editButtonImg" />
            <p
              className="editButtonText"
              id={`pdfPreviewEditButtonText-court`}
              data-textattribute="button-88"
            >
              {getText('button-88', user.settings)}
            </p>
          </div>
        ) : null}
        {courtEditMode ? (
          <input
            type="text"
            className="instanceInput"
            value={editableCourtText}
            placeholder={typesOfTrialTitles[props.trial][props.defaultInstance]}
            id={`instanceInput-dateOftrialComponent-${props.trial}`}
            onChange={(e) => {
              setEditableCourtText(e.target.value)
            }}
            maxLength={70}
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionTitle,
              FontLabels.regular,
              'noRoboto',
            )}
          />
        ) : (
          <p
            className="instanceTitle"
            onClick={() => {
              setCourtEditMode(true)
              setShowCourtEditButton(false)
            }}
            id={`instanceTitle-dateOftrialComponent-${props.trial}`}
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionTitle,
              FontLabels.regular,
              'noRoboto',
            )}
          >
            {isCourtEmpty
              ? typesOfTrialTitles[props.trial][props.defaultInstance]
              : editableCourtText}
          </p>
        )}
      </div>
      <div
        className="dateOfTrialDateContainer"
        onMouseEnter={
          dateTextEditMode ? undefined : () => setShowDateTextEditButton(true)
        }
        onMouseLeave={() => setShowDateTextEditButton(false)}
      >
        {showDateTextEditButton ? (
          <div
            className="editButtonContainer"
            onClick={() => {
              setDateTextEditMode(true)
              setShowDateTextEditButton(false)
            }}
            style={
              user.settings.language === AvailableLanguages.norwegian ||
              user.settings.language === AvailableLanguages.swedish
                ? { marginTop: '7px', marginLeft: '-24px', fontSize: '14px' }
                : { marginTop: '5px' }
            }
          >
            <img src={editButtonImg} alt="" className="editButtonImg" />
            <p
              className="editButtonText"
              id={`pdfPreviewEditButtonText-trialDates`}
              data-textattribute="button-88"
            >
              {getText('button-88', user.settings)}
            </p>
          </div>
        ) : null}
        <div className="dateOfTrialIntroTextContainer">
          {dateTextEditMode ? (
            <input
              type="text"
              className="dateOfTrialIntroTextInput"
              value={editableDateText}
              placeholder={introText}
              id={`dateOfTrialIntroTextInput-dateOftrialComponent-${props.trial}`}
              onChange={(e) => {
                setEditableDateText(e.target.value)
              }}
              maxLength={30}
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.regular,
                'noRoboto',
              )}
            />
          ) : (
            <p
              className="dateOfTrialIntroText"
              id={`dateOfTrialIntroText-dateOftrialComponent-${props.trial}`}
              onClick={() => {
                setDateTextEditMode(true)
                setShowDateTextEditButton(false)
              }}
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.regular,
                'noRoboto',
              )}
            >
              {isDateTextEmpty ? introText : editableDateText}
            </p>
          )}
        </div>
        <p
          className="dateOfTrialDate"
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'noRoboto',
          )}
        >
          <span>:</span>{' '}
          {translateDate(
            DateTime.fromISO(props.date as string).toFormat('dd LLL yyyy'),
            user.settings.language,
          )}
        </p>
      </div>
    </div>
  )
}
