import { keysIn } from 'lodash'
import {
  AvailableLanguages,
  getReadableValueFromPartyFormatForReport,
  NorwegianPartyFormat,
  PartyFormat,
  SwedishPartyFormat,
} from '../models/enums'
import { UserSettings } from '../models/userSettings'
import englishTexts from '../resources/texts/englishTexts.json'
import norwegianTexts from '../resources/texts/norwegianTexts.json'
import swedishTexts from '../resources/texts/swedishTexts.json'
import { getHelperText } from './dateFunctions'
import { ValuesBreakdown } from '../models/generalTypes'

function replacePartiesInText(
  text: string,
  partiesFormat: (keyof typeof PartyFormat | String)[],
  language: AvailableLanguages,
) {
  let tempText = text
  if (
    tempText.toLowerCase().includes(quickTranslate('the client', language)) &&
    partiesFormat[0] !== '' &&
    !keysIn(PartyFormat).includes(partiesFormat[0] as string)
  ) {
    tempText = tempText.replaceAll(
      quickTranslate('The client', language),
      'tempReplacement0',
    )
    tempText = tempText.replaceAll(
      quickTranslate('the client', language),
      'tempReplacement0',
    )
  } else if (
    tempText.toLowerCase().includes(quickTranslate('client', language)) &&
    partiesFormat[0] !== ''
  ) {
    tempText = tempText.replaceAll(
      quickTranslate('client', language),
      'tempReplacement0-LowerCase',
    )
    tempText = tempText.replaceAll(
      quickTranslate('Client', language),
      'tempReplacement0',
    )
    tempText = tempText.replaceAll(
      quickTranslate('CLIENT', language),
      'tempReplacement0-UpperCase',
    )
  }
  if (
    tempText
      .toLowerCase()
      .includes(quickTranslate('the opposing party', language)) &&
    partiesFormat[1] !== '' &&
    !keysIn(PartyFormat).includes(partiesFormat[1] as string) &&
    !keysIn(NorwegianPartyFormat).includes(partiesFormat[1] as string) &&
    !keysIn(SwedishPartyFormat).includes(partiesFormat[1] as string) &&
    partiesFormat[1] !== quickTranslate('Opposing Party', language)
  ) {
    tempText = tempText.replaceAll(
      quickTranslate('The opposing party', language),
      'tempReplacement1',
    )
    tempText = tempText.replaceAll(
      quickTranslate('the opposing party', language),
      'tempReplacement1',
    )
  } else if (
    tempText
      .toLowerCase()
      .includes(quickTranslate('opposing party', language)) &&
    partiesFormat[1] !== ''
  ) {
    tempText = tempText.replaceAll(
      quickTranslate('opposing party', language),
      'tempReplacement1-LowerCase',
    )
    tempText = tempText.replaceAll(
      quickTranslate('Opposing Party', language),
      'tempReplacement1',
    )
    tempText = tempText.replaceAll(
      quickTranslate('OPPOSING PARTY', language),
      'tempReplacement1-UpperCase',
    )
  }

  let ownPartyFormat = partiesFormat[0] as string
  let otherPartyFormat = partiesFormat[1] as string
  if (ownPartyFormat === '') {
    ownPartyFormat = quickTranslate('Client', language)
  } else {
    ownPartyFormat = getReadableValueFromPartyFormatForReport(
      ownPartyFormat,
      language,
    )
  }
  if (otherPartyFormat === '') {
    otherPartyFormat = quickTranslate('Opposing Party', language)
  } else {
    otherPartyFormat = getReadableValueFromPartyFormatForReport(
      otherPartyFormat,
      language,
    )
  }

  tempText = tempText.replaceAll(
    'tempReplacement0-UpperCase',
    ownPartyFormat.toUpperCase(),
  )
  tempText = tempText.replaceAll(
    'tempReplacement0-LowerCase',
    ownPartyFormat.toLowerCase(),
  )
  tempText = tempText.replaceAll('tempReplacement0', ownPartyFormat as string)
  tempText = tempText.replaceAll(
    'tempReplacement1-UpperCase',
    otherPartyFormat.toUpperCase(),
  )
  tempText = tempText.replaceAll(
    'tempReplacement1-LowerCase',
    otherPartyFormat.toLowerCase(),
  )
  tempText = tempText.replaceAll('tempReplacement1', otherPartyFormat as string)

  tempText = correctPossesiveForm(tempText, language)

  return tempText
}

export function quickTranslate(text: string, language: AvailableLanguages) {
  switch (language) {
    case AvailableLanguages.norwegian:
      switch (text) {
        case 'the client':
          return 'klienten'
        case 'The client':
          return 'Klienten'
        case 'client':
          return 'klient'
        case 'Client':
          return 'Klient'
        case 'CLIENT':
          return 'KLIENT'
        case 'the opposing party':
          return 'motparten'
        case 'The opposing party':
          return 'Motparten'
        case 'opposing party':
          return 'motpart'
        case 'Opposing Party':
          return 'Motpart'
        case 'OPPOSING PARTY':
          return 'MOTPART'
        case 'No Interest':
          return 'Ingen rente'
        case 'Interest 1st':
          return 'Rente 1.'
        case 'Interest 2nd':
          return 'Rente 2.'
        default:
          return text
      }
    case AvailableLanguages.swedish:
      switch (text) {
        case 'the client':
          return 'klienten'
        case 'The client':
          return 'Klienten'
        case 'client':
          return 'klient'
        case 'Client':
          return 'Klient'
        case 'CLIENT':
          return 'KLIENT'
        case 'the opposing party':
          return 'motparten'
        case 'The opposing party':
          return 'Motparten'
        case 'opposing party':
          return 'motpart'
        case 'Opposing Party':
          return 'Motpart'
        case 'OPPOSING PARTY':
          return 'MOTPART'
        case 'No Interest':
          return 'Ingen ränta'
        case 'Interest 1st':
          return 'Ränta 1:a'
        case 'Interest 2nd':
          return 'Ränta 2:a'
        default:
          return text
      }
    case AvailableLanguages.english:
      return text
  }
  return text
}

function replaceDatesInText(text: string, settings: UserSettings) {
  let tempText = text

  tempText = tempText.replaceAll('yyyy/mm/dd', getHelperText(settings))

  return tempText
}

export function getText(
  key: keyof typeof englishTexts,
  settings: UserSettings,
  partiesFormat?: (keyof typeof PartyFormat | String)[],
) {
  const languageJson = {
    english: englishTexts,
    norwegian: norwegianTexts,
    swedish: swedishTexts,
  }

  let res = languageJson[settings.language][key]
  let clientPartyEndsInS =
    (partiesFormat &&
      partiesFormat[0][partiesFormat[0].length - 1].toLowerCase() === 's') ||
    (partiesFormat &&
      partiesFormat[0][partiesFormat[0].length - 1].toLowerCase() === 'x') ||
    (partiesFormat &&
      partiesFormat[0][partiesFormat[0].length - 1].toLowerCase() === 'z')
  let opposingPartyEndsInS =
    (partiesFormat &&
      partiesFormat[1][partiesFormat[1].length - 1].toLowerCase() === 's') ||
    (partiesFormat &&
      partiesFormat[1][partiesFormat[1].length - 1].toLowerCase() === 'x') ||
    (partiesFormat &&
      partiesFormat[1][partiesFormat[1].length - 1].toLowerCase() === 'z')

  if (clientPartyEndsInS) {
    switch (settings.language) {
      case AvailableLanguages.norwegian:
        res = res.replaceAll('klientens', 'klienten')
        res = res.replaceAll('Klientens', 'Klienten')
        break
      case AvailableLanguages.swedish:
        res = res.replaceAll('klientens', 'klienten')
        res = res.replaceAll('Klientens', 'Klienten')
        break
      case AvailableLanguages.english:
        res = res.replaceAll("client's", "client'")
        res = res.replaceAll("Client's", "Client'")
        if (key === 'message-76') {
          res = res.replaceAll("'s", "''")
        }
    }
  }
  if (opposingPartyEndsInS) {
    switch (settings.language) {
      case AvailableLanguages.norwegian:
        res = res.replaceAll('motpartens', 'motparten')
        res = res.replaceAll('Motpartens', 'Motparten')
        break
      case AvailableLanguages.swedish:
        res = res.replaceAll('motpartens', 'motparten')
        res = res.replaceAll('Motpartens', 'Motparten')
        break
      case AvailableLanguages.english:
        res = res.replaceAll("opposing party's", "opposing party'")
        res = res.replaceAll("Opposing Party's", "Opposing Party'")
        if (key === 'message-76') {
          res = res.replaceAll("'s", "''")
        }
    }
  }

  if (partiesFormat) {
    res = replacePartiesInText(res, partiesFormat, settings.language)
  }
  res = replaceDatesInText(res, settings)

  return res
}

export function translateClaimTypeId(
  claimtypeId: string,
  settings: UserSettings,
  partiesFormat: (keyof typeof PartyFormat | String)[],
) {
  if (claimtypeId.includes('Decision Tree')) {
    //id='claimTypeIdTitle'
    // data-attribute="title-34"
    return claimtypeId.replace(
      'Decision Tree',
      getText('title-34', settings, partiesFormat),
    )
  }
  if (claimtypeId.includes('Client Claim')) {
    //id='claimTypeIdTitle'
    // data-attribute="title-32"
    return getSplitText(
      claimtypeId.replace(
        'Client Claim',
        getText('title-32', settings, partiesFormat),
      ),
      ' Claim',
      36,
      32,
    )
  }
  if (claimtypeId.includes('Opposing Party Claim')) {
    //id='claimTypeIdTitle'
    // data-attribute="title-33"
    return getSplitText(
      claimtypeId.replace(
        'Opposing Party Claim',
        getText('title-33', settings, partiesFormat),
      ),
      ' Claim',
      36,
      32,
    )
  }
}

export function saveMenuMessages(settings: UserSettings) {
  return {
    // data-textattribute = 'message-29'
    academy: getText('message-29', settings),
    // data-textattribute = 'message-94'
    settings: getText('message-94', settings),
    // data-textattribute = 'message-30'
    mycases: getText('message-30', settings),
    // data-textattribute = 'message-31'
    myscenarios: getText('message-31', settings),
    // data-textattribute = 'message-32'
    logOut: getText('message-32', settings),
    // data-textattribute = 'message-33'
    anotherScenario: getText('message-33', settings),
    // data-textattribute = 'message-34'
    closeReport: getText('message-34', settings),
  }
}

export function errorMessages(settings: UserSettings) {
  return {
    //data-textattribute = 'error-47, error-74'
    // eslint-disable-next-line no-useless-concat
    WRONG_CREDENTIALS: `${getText('error-47', settings)}\\n${getText(
      'error-74',
      settings,
    )}`,
    //data-textattribute = 'error-48'
    LOCKED_ACCOUNT: getText('error-48', settings),
    //data-textattribute = 'error-49'
    WRONG_CODE: getText('error-49', settings),
    //data-textattribute = 'error-50'
    WRONG_CODE_SMS: getText('error-50', settings),
    //data-textattribute = 'error-51'
    OTP_REQUEST_FAILED: getText('error-51', settings),
    //data-textattribute = 'error-52'
    NOT_ENOUGH_DIGITS: getText('error-52', settings),
    //data-textattribute = 'error-53'
    WRONG_BACKUP: getText('error-53', settings),
    //data-textattribute = 'error-54'
    WRONG_NUMBER: getText('error-54', settings),
    //data-textattribute = 'error-55'
    RESET_PASSWORD_FAILED: getText('error-55', settings),
    //data-textattribute = 'error-56'
    SETUP_TWO_FACTOR_ERROR: getText('error-56', settings),
    //data-textattribute = 'error-57'
    SET_SAVED_BACK_UP_CODES_FAILED: getText('error-57', settings),
    //data-textattribute = 'error-58'
    HAS_SAVED_BACK_UP_CODES_FAILED: getText('error-58', settings),
    //data-textattribute = 'error-59'
    GET_BACK_UP_CODES_FAILED: getText('error-59', settings),
    //data-textattribute = 'error-60'
    SETUP_SMS_DEVICE_FAILED: getText('error-60', settings),
    //data-textattribute = 'error-61'
    SEND_SMS_FAILED: getText('error-61', settings),
    //data-textattribute = 'error-62'
    LOGIN_FAILED: getText('error-62', settings),
    //data-textattribute = 'error-63'
    FORBIDDEN: getText('error-63', settings),
    //data-textattribute = 'error-64'
    CREATE_NEW_CASE_FAILED: getText('error-64', settings),
    //data-textattribute = 'error-65'
    SERVER_ERROR: getText('error-65', settings),
  }
}

//id='reportSectionType'
export function reportSectionType(settings: UserSettings) {
  return {
    //data-textattribute='title-132'
    ExecutiveSummary: getText('title-132', settings),
    //data-textattribute='title-133'
    CaseDescription: getText('title-133', settings),
    //data-textattribute='title-134'
    Proceedings: getText('title-134', settings),
    //data-textattribute='title-232'
    ClaimsOverview: getText('title-232', settings),
    //data-textattribute='title-135'
    Claims: getText('title-135', settings),
    //data-textattribute='title-136'
    LegalCosts: getText('title-136', settings),
    //data-textattribute='title-137'
    LegalCostsDistribution: getText('title-137', settings),
    //data-textattribute='title-138'
    EnforcementRisk: getText('title-138', settings),
    //data-textattribute='title-139'
    SelectionGraph: getText('title-139', settings),
    //data-textattribute='title-140'
    ProbabilityDistributionGraph: getText('title-140', settings),
    //data-textattribute='title-263'
    GetPayGraph: getText('title-263', settings),
    //data-textattribute='title-141'
    BestWorstScenario: getText('title-141', settings),
    //data-textattribute='title-142'
    SettlementAnalysis: getText('title-142', settings),
    //data-textattribute='title-138b'
    ResultsReport: getText('title-138b', settings),
    //data-textattribute='title-276'
    ExpectedValueBreakdown: getText('title-276', settings),
    //data-textattribute='title-208'
    CustomSection: getText('title-208', settings),
  }
}

//id='reportSectionPlaceholder'
export function reportSectionPlaceholders(settings: UserSettings) {
  return {
    //data-textattribute='placeholder-4'
    ExecutiveSummary: getText('placeholder-4', settings),
    //data-textattribute='placeholder-5'
    CaseDescription: getText('placeholder-5', settings),
    //data-textattribute='placeholder-6'
    Proceedings: getText('placeholder-6', settings),
    //data-textattribute='placeholder-18'
    ClaimsOverview: getText('placeholder-18', settings),
    //data-textattribute='placeholder-7'
    Claims: getText('placeholder-7', settings),
    //data-textattribute='placeholder-8'
    LegalCosts: getText('placeholder-8', settings),
    //data-textattribute='placeholder-9'
    LegalCostsDistribution: getText('placeholder-9', settings),
    //data-textattribute='placeholder-10'
    EnforcementRisk: getText('placeholder-10', settings),
    //data-textattribute='placeholder-11'
    SelectionGraph: getText('placeholder-11', settings),
    //data-textattribute='placeholder-19'
    GetPayGraph: getText('placeholder-19', settings),
    //data-textattribute='placeholder-12'
    ProbabilityDistributionGraph: getText('placeholder-12', settings),
    //data-textattribute='placeholder-13'
    BestWorstScenario: getText('placeholder-13', settings),
    //data-textattribute='placeholder-14'
    SettlementAnalysis: getText('placeholder-14', settings),
    //data-textattribute='placeholder-4'
    ResultsReport: getText('placeholder-4', settings),
    //data-textattribute='placeholder-20'
    ExpectedValueBreakdown: getText('placeholder-20', settings),
    //data-textattribute='placeholder-17'
    CustomSection: getText('placeholder-17', settings),
  }
}

export function getActionMessage(settings: UserSettings) {
  return {
    //data-textattribute='message-95'
    'Downloading Image': getText('message-95', settings),
    //data-textattribute='message-129'
    'Downloading Results': getText('message-129', settings),
    //data-textattribute='message-96'
    'Download Failed': getText('message-96', settings),
  }
}

export function getSplitText(
  string: string,
  splitter: string,
  stringLength: number,
  substringLength: number,
) {
  if (string.length > stringLength) {
    let properTextArray = string.split(splitter)

    properTextArray[0] =
      properTextArray[0].substring(0, substringLength).trim() + '...' + splitter
    string = properTextArray.join('')
  }
  return string
}

export function getTextFromValueBreakdown(
  valueBreakdownKey: keyof ValuesBreakdown,
  settings: UserSettings,
  partiesFormat: (keyof typeof PartyFormat | String)[],
  includeIncurredCosts: boolean,
) {
  switch (valueBreakdownKey) {
    case 'valueOfClientClaims': // 1
      return getSplitText(
        getText('title-32', settings, partiesFormat),
        settings.language === AvailableLanguages.english
          ? ' Claim'
          : settings.language === AvailableLanguages.swedish
          ? 's krav'
          : 's krav',
        36,
        32,
      )
    case 'valueOfClientInterest': // 2
    case 'valueOfOpposingPartyInterest': // 6
      return getText('title-51', settings, partiesFormat)
    case 'valueOfLegalCostsToBePaidByOpposingParty': // 3
      return `${getText('title-61', settings)} ${getText(
        'title-61b',
        settings,
      )} ${
        getText('title-61c', settings, partiesFormat).length >
        (settings.language === AvailableLanguages.english ? 40 : 25)
          ? getText('title-61c', settings, partiesFormat)
              .substring(
                0,
                settings.language === AvailableLanguages.english ? 26 : 11,
              )
              .trim() + '...'
          : getText('title-61c', settings, partiesFormat)
      }`
    case 'valueOfLegalCostsCoveredByInsurance': // 4
      return getText('title-104', settings, partiesFormat)
    case 'valueOfOpposingPartyClaims': // 5
      return getSplitText(
        getText('title-33', settings, partiesFormat),
        settings.language === AvailableLanguages.english
          ? ' Claim'
          : settings.language === AvailableLanguages.swedish
          ? 's krav'
          : 's krav',
        36,
        32,
      )
    case 'valueOfLegalCostsToBePaidToOpposingParty': // 7
      return `${getText('title-62', settings)} ${getText(
        'title-62b',
        settings,
      )} ${
        getText('title-62c', settings, partiesFormat).length >
        (settings.language === AvailableLanguages.english ? 40 : 25)
          ? getText('title-62c', settings, partiesFormat)
              .substring(
                0,
                settings.language === AvailableLanguages.english ? 26 : 11,
              )
              .trim() + '...'
          : getText('title-62c', settings, partiesFormat)
      }`
    case 'valueOfUnsuccessfulEnforcement': // 8
      return getText('title-65', settings, partiesFormat)
    case 'valueOfExternalValues': // 9
      return getText('title-50', settings, partiesFormat)
    case 'valueOfLegalCosts': // 10
      if (!includeIncurredCosts) {
        return getSplitText(
          getText('title-279', settings, partiesFormat),
          settings.language === AvailableLanguages.norwegian
            ? 's framtida sakskostnader'
            : settings.language === AvailableLanguages.swedish
            ? 's framtida rättegångskostnader'
            : '’s',
          39,
          settings.language === AvailableLanguages.english ? 25 : 14,
        )
      } else {
        return getSplitText(
          getText('title-60', settings, partiesFormat),
          settings.language === AvailableLanguages.norwegian
            ? 's sakskostnader'
            : settings.language === AvailableLanguages.swedish
            ? 's rättegångskostnader'
            : '’s',
          39,
          25,
        )
      }

    default:
      return ''
  }
}

export function getSimpleText(
  key: keyof typeof norwegianTexts,
  language: AvailableLanguages,
): string {
  switch (language) {
    case AvailableLanguages.norwegian:
      return norwegianTexts[key]
    case AvailableLanguages.swedish:
      return swedishTexts[key]
    case AvailableLanguages.english:
      return englishTexts[key]
    default:
      return englishTexts[key]
  }
}

function correctPossesiveForm(text: string, language: AvailableLanguages) {
  switch (language) {
    case AvailableLanguages.norwegian:
      let tempText = text
      tempText = tempText.replaceAll('saksøkteen', 'saksøkte')
      tempText = tempText.replaceAll('Saksøkteen', 'Saksøkte')

      tempText = tempText.replaceAll('owner memberen', 'owner member')
      tempText = tempText.replaceAll('Owner Memberen', 'Owner Member')

      tempText = tempText.replaceAll('charterer memberen', 'charterer member')
      tempText = tempText.replaceAll('Charterer Memberen', 'Charterer Member')

      tempText = tempText.replaceAll(
        'forsikringsselskapen',
        'forsikringsselskapet',
      )
      tempText = tempText.replaceAll(
        'Forsikringsselskapen',
        'Forsikringsselskapet',
      )

      return tempText
    case AvailableLanguages.swedish:
      let tempText2 = text
      tempText2 = tempText2.replaceAll('charterer memberen', 'charterer member')
      tempText2 = tempText2.replaceAll('Charterer Memberen', 'Charterer Member')

      tempText2 = tempText2.replaceAll('owner memberen', 'owner member')
      tempText2 = tempText2.replaceAll('Owner Memberen', 'Owner Member')

      tempText2 = tempText2.replaceAll('kärandeen', 'käranden')
      tempText2 = tempText2.replaceAll('Kärandeen', 'Käranden')

      tempText2 = tempText2.replaceAll('svarandeen', 'svaranden')
      tempText2 = tempText2.replaceAll('Svarandeen', 'Svaranden')

      tempText2 = tempText2.replaceAll(
        'försäkringsgivareen',
        'försäkringsgivaren',
      )
      tempText2 = tempText2.replaceAll(
        'Försäkringsgivareen',
        'Försäkringsgivaren',
      )

      return tempText2
    case AvailableLanguages.english:
      return text
    default:
      return text
  }
}

export function translateDate(date: string, language: AvailableLanguages) {
  switch (language) {
    case AvailableLanguages.norwegian:
      let tempDate1 = date
      tempDate1 = tempDate1.replaceAll('Jan', 'jan')
      tempDate1 = tempDate1.replaceAll('Feb', 'feb')
      tempDate1 = tempDate1.replaceAll('Mar', 'mar')
      tempDate1 = tempDate1.replaceAll('Apr', 'apr')
      tempDate1 = tempDate1.replaceAll('May', 'maj')
      tempDate1 = tempDate1.replaceAll('Jun', 'jun')
      tempDate1 = tempDate1.replaceAll('Jul', 'jul')
      tempDate1 = tempDate1.replaceAll('Aug', 'aug')
      tempDate1 = tempDate1.replaceAll('Sep', 'sep')
      tempDate1 = tempDate1.replaceAll('Oct', 'okt')
      tempDate1 = tempDate1.replaceAll('Nov', 'nov')
      tempDate1 = tempDate1.replaceAll('Dec', 'des')
      let tempDate1Array = tempDate1.split(' ')
      tempDate1Array[0] = tempDate1Array[0] + '.'
      if (tempDate1Array[0].startsWith('0')) {
        tempDate1Array[0] = tempDate1Array[0].slice(1)
      }
      tempDate1 = tempDate1Array.join(' ')
      return tempDate1
    case AvailableLanguages.swedish:
      let tempDate2 = date
      tempDate2 = tempDate2.replaceAll('Jan', 'jan')
      tempDate2 = tempDate2.replaceAll('Feb', 'feb')
      tempDate2 = tempDate2.replaceAll('Mar', 'mar')
      tempDate2 = tempDate2.replaceAll('Apr', 'apr')
      tempDate2 = tempDate2.replaceAll('May', 'maj')
      tempDate2 = tempDate2.replaceAll('Jun', 'jun')
      tempDate2 = tempDate2.replaceAll('Jul', 'jul')
      tempDate2 = tempDate2.replaceAll('Aug', 'aug')
      tempDate2 = tempDate2.replaceAll('Sep', 'sep')
      tempDate2 = tempDate2.replaceAll('Oct', 'okt')
      tempDate2 = tempDate2.replaceAll('Nov', 'nov')
      tempDate2 = tempDate2.replaceAll('Dec', 'dec')
      let tempDate2Array = tempDate2.split(' ')
      if (tempDate2Array[0].startsWith('0')) {
        tempDate2Array[0] = tempDate2Array[0].slice(1)
      }
      tempDate2 = tempDate2Array.join(' ')
      return tempDate2
    case AvailableLanguages.english:
      let tempDate3 = date
      let tempDate3Array = tempDate3.split(' ')
      if (tempDate3Array[0].startsWith('0')) {
        tempDate3Array[0] = tempDate3Array[0].slice(1)
      }
      tempDate3 = tempDate3Array.join(' ')
      return tempDate3
    default:
      return date
  }
}
