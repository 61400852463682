import React, { useState, useEffect, useRef } from 'react'

import OutcomeTitle from './hoverResultsComponents/OutcomeTitle'
import Description from './hoverResultsComponents/Description'
import PrincipalAmount from './hoverResultsComponents/PrincipalAmount'
import InterestAwarded from './hoverResultsComponents/InterestAwarded'
import LegalCostsBy from './hoverResultsComponents/LegalCostsBy'
import LegalCostsTo from './hoverResultsComponents/LegalCostsTo'
import LegalCostsByInsurance from './hoverResultsComponents/LegalCostsByInsurance'
import ExternalConsequence from './hoverResultsComponents/ExternalConsequence'
import UnsuccessfulEnforcement from './hoverResultsComponents/UnsuccessfulEnforcement'
import ProbabilityOfOutcome from './hoverResultsComponents/ProbabilityOfOutcome'
import WeightedProbability from './hoverResultsComponents/WeightedProbability'
import HoverComponent from '../../../../../../../Components/InfoHover/HoverComponent/HoverComponent'
import DescriptionTitleHover from './hoverResultsComponents/DescriptionTitleHover'

import firstTrialImg from '../../../../../../../resources/images/137-first.svg'
import secondTrialImg from '../../../../../../../resources/images/140-second.svg'
import bankruptcyFirstTrialImg from '../../../../../../../resources/images/138-firstCourtBunkruptcy.svg'
import bankruptcySecondTrialImg from '../../../../../../../resources/images/139-secondCourtBunkruptcy.svg'

import { ScenarioSnapshot } from '../../../../../../../models/scenarioSnapshot'
import { resultsFontSize } from '../../../../../../../services/commonFunctions'
import { ResultsObject } from '../../../../../../../models/generalTypes'
import {
  AvailableLanguages,
  TypeOfInstance,
} from '../../../../../../../models/enums'
import { ResultsTooltip } from '../../../../../../../Components/tooltip/TooltipComponent'
import {
  initializeDataForTable,
  showEnforcement,
  showInsurance,
  showInterest,
  showLegalFees,
  showOutOfCourt,
} from '../../../../../../../services/resultsFunctions'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../../../../../states/UserState'
import {
  getSplitText,
  getText,
} from '../../../../../../../services/textFunctions'
import { ReportWhitelabel } from '../../../../../../../models/reportModels/reportGeneralTypes'
import {
  getStyleFromWhitelabel,
  snapshotHasEnforcementRisk,
} from '../../../../../../../services/reportFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../models/reportModels/reportEnums'
import { stringAndRoundBigNumbers } from '../../../../../../../Modules/DisputeModules/AppFunctionsNew'
import { resultsSettingsState } from '../../../../../../../states/ResultsSettingsState'

type Props = {
  widthOfDiv: number
  heightOfDiv: number
  resultsFromBackend: ResultsObject
  resultsSnapshot: ScenarioSnapshot
  previewPdf?: boolean
  reportWhitelabel?: ReportWhitelabel
  resultsViewParty: 'client' | 'opposing'
}

export default function BestWorstScenario(props: Props) {
  const resultsSettings = useRecoilValue(resultsSettingsState)
  const [dataMaxMin, setDataMinMax] = useState([
    props.resultsFromBackend.minmax.max,
    props.resultsFromBackend.minmax.min,
  ])
  const user = useRecoilValue(userState)
  const [sortedDataForTable, setSortedDataForTable] = useState(
    initializeDataForTable(
      dataMaxMin,
      props.resultsSnapshot,
      true,
      resultsSettings.settings.includeIncurredCosts,
      user.settings,
    ),
  )
  const [hoverElement, setHoverElement] = useState('')
  const secondaryHover = useRef('open')

  const partiesFormat = [
    props.resultsSnapshot.partyFormatOwn,
    props.resultsSnapshot.partyFormatOther,
  ]

  const onMouseDown = (e: any) => {
    setHoverElement('')

    // eslint-disable-next-line
  }

  const [dataForShowingRows, setDataForShowingRows] = useState(
    initializeDataForTable(
      dataMaxMin,
      props.resultsSnapshot,
      true,
      resultsSettings.settings.includeIncurredCosts,
      user.settings,
    ),
  )

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)
    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [hoverElement])

  useEffect(() => {
    setDataMinMax([
      props.resultsFromBackend.minmax.max,
      props.resultsFromBackend.minmax.min,
    ])

    setDataForShowingRows(
      initializeDataForTable(
        [
          props.resultsFromBackend.minmax.max,
          props.resultsFromBackend.minmax.min,
        ],
        props.resultsSnapshot,
        true,
        resultsSettings.settings.includeIncurredCosts,
        user.settings,
      ),
    )

    setSortedDataForTable(
      initializeDataForTable(
        [
          props.resultsFromBackend.minmax.max,
          props.resultsFromBackend.minmax.min,
        ],
        props.resultsSnapshot,
        true,
        resultsSettings.settings.includeIncurredCosts,
        user.settings,
      ),
    )

    // eslint-disable-next-line
  }, [props.resultsFromBackend, resultsSettings, user.settings.language])

  return (
    <>
      <div className="horizontalResultsContainer">
        <div
          className={
            props.previewPdf
              ? 'headerBackground previewPdf'
              : 'headerBackground'
          }
        ></div>
        <div className="headersContainer bestWorstHeadersContainer">
          <div className="headersRows">
            <div
              className="title title1"
              onMouseEnter={() => {
                secondaryHover.current = 'outcomeID'
                setTimeout(() => {
                  if (secondaryHover.current === 'outcomeID') {
                    setHoverElement('outcomeID')
                  }
                }, 1000)
              }}
              onMouseLeave={() => {
                secondaryHover.current = 'close'
                setHoverElement('')
              }}
            >
              <p
                id="resultsTableTitle-outcomeId"
                data-textattribute="title-54"
                className="resultsTableTitleInRow"
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  'noRoboto',
                )}
              >
                {getText('title-54', user.settings)}
              </p>
              {hoverElement === 'outcomeIDERROR INTENTIONALLY' ? (
                <div
                  className="hoverComponentContainer"
                  style={{ top: '10px', right: '80px' }}
                >
                  <HoverComponent
                    hoverText={getText('hover-33', user.settings)}
                    textAttribute="hover-33"
                    id="outcomeID"
                  />
                </div>
              ) : null}
            </div>
            <div
              className="row row1"
              onMouseEnter={() => {
                secondaryHover.current = 'description'
                setTimeout(() => {
                  if (secondaryHover.current === 'description') {
                    setHoverElement('description')
                  }
                }, 1000)
              }}
              onMouseLeave={() => {
                secondaryHover.current = 'close'
                setHoverElement('')
              }}
            >
              <p
                id="resultsTableTitle-description"
                data-textattribute="title-55"
                className="resultsTableTitleInRow"
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  'noRoboto',
                )}
              >
                {getText('title-55', user.settings)}
              </p>
              {hoverElement === 'description' ? (
                <div
                  className="hoverComponentContainer"
                  style={{ top: '10px', right: '-240px' }}
                >
                  <HoverComponent
                    hoverText={
                      <>
                        <DescriptionTitleHover
                          firstTrialImg={firstTrialImg}
                          secondTrialImg={secondTrialImg}
                          bankruptcyFirstTrialImg={bankruptcyFirstTrialImg}
                          bankruptcySecondTrialImg={bankruptcySecondTrialImg}
                        />
                      </>
                    }
                    textAttribute=""
                    id="description"
                    style={{ width: '520px' }}
                  />
                </div>
              ) : null}
            </div>
            <div
              className="row row2"
              onMouseEnter={() => {
                secondaryHover.current = 'probability'
                setTimeout(() => {
                  if (secondaryHover.current === 'probability') {
                    setHoverElement('probability')
                  }
                }, 1000)
              }}
              onMouseLeave={() => {
                secondaryHover.current = 'close'
                setHoverElement('')
              }}
            >
              <p
                id="resultsTableTitle-probability"
                data-textattribute="title-56"
                className="resultsTableTitleInRow"
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  'noRoboto',
                )}
              >
                {getText('title-56', user.settings)}
              </p>
              {hoverElement === 'probability' ? (
                <div
                  className="hoverComponentContainer"
                  style={{ top: '10px', right: '20px' }}
                >
                  <HoverComponent
                    hoverText={getText('hover-38', user.settings)}
                    textAttribute="hover-38"
                    id="probability"
                  />
                </div>
              ) : null}
            </div>
            <div className="title title2">
              <p
                id="resultsTableTitle-monetaryOutcome"
                data-textattribute="title-57"
                className="resultsTableTitleInRow"
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  'noRoboto',
                )}
              >
                {getText('title-57', user.settings)}
              </p>
            </div>
            <div
              className="row row3"
              onMouseEnter={() => {
                secondaryHover.current = 'principalAmount'
                setTimeout(() => {
                  if (secondaryHover.current === 'principalAmount') {
                    setHoverElement('principalAmount')
                  }
                }, 1000)
              }}
              onMouseLeave={() => {
                secondaryHover.current = 'close'
                setHoverElement('')
              }}
            >
              <p
                id="resultsTableTitle-amountAwarded"
                data-textattribute="title-58"
                className="resultsTableTitleInRow"
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  'noRoboto',
                )}
              >
                {getText('title-58', user.settings)}
              </p>
              {hoverElement === 'principalAmount' ? (
                <div
                  className="hoverComponentContainer"
                  style={{ top: '10px', right: '24px' }}
                >
                  <HoverComponent
                    hoverText={
                      props.resultsSnapshot.court ===
                      TypeOfInstance.Public_Court
                        ? getText('hover-39', user.settings, partiesFormat)
                        : props.resultsSnapshot.court ===
                          TypeOfInstance.Arbitration
                        ? getText('hover-40', user.settings, partiesFormat)
                        : getText('hover-41', user.settings, partiesFormat)
                    }
                    textAttribute={
                      props.resultsSnapshot.court ===
                      TypeOfInstance.Public_Court
                        ? 'hover-39'
                        : props.resultsSnapshot.court ===
                          TypeOfInstance.Arbitration
                        ? 'hover-40'
                        : 'hover-41'
                    }
                    id="principalAmount"
                  />
                </div>
              ) : null}
            </div>
            {showInterest(dataForShowingRows) ? (
              <div
                className="row row4"
                onMouseEnter={() => {
                  secondaryHover.current = 'interestAwarded'
                  setTimeout(() => {
                    if (secondaryHover.current === 'interestAwarded') {
                      setHoverElement('interestAwarded')
                    }
                  }, 1000)
                }}
                onMouseLeave={() => {
                  secondaryHover.current = 'close'
                  setHoverElement('')
                }}
              >
                <p
                  id="resultsTableTitle-interest"
                  data-textattribute="title-59"
                  className="resultsTableTitleInRow"
                  style={getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.sectionDescription,
                    FontLabels.regular,
                    'noRoboto',
                  )}
                >
                  {getText('title-59', user.settings)}
                </p>
                {hoverElement === 'interestAwarded' ? (
                  <div
                    className="hoverComponentContainer"
                    style={{ top: '10px', right: '24px' }}
                  >
                    <HoverComponent
                      hoverText={
                        props.resultsSnapshot.court ===
                        TypeOfInstance.Public_Court
                          ? getText('hover-42', user.settings, partiesFormat)
                          : props.resultsSnapshot.court ===
                            TypeOfInstance.Arbitration
                          ? getText('hover-43', user.settings, partiesFormat)
                          : getText('hover-44', user.settings, partiesFormat)
                      }
                      textAttribute={
                        props.resultsSnapshot.court ===
                        TypeOfInstance.Public_Court
                          ? 'hover-42'
                          : props.resultsSnapshot.court ===
                            TypeOfInstance.Arbitration
                          ? 'hover-43'
                          : 'hover-44'
                      }
                      id="interestAwarded"
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            {showLegalFees(dataForShowingRows) ? (
              <>
                <div
                  className="row row5"
                  onMouseEnter={() => {
                    secondaryHover.current = 'legalCosts'
                    setTimeout(() => {
                      if (secondaryHover.current === 'legalCosts') {
                        setHoverElement('legalCosts')
                      }
                    }, 1000)
                  }}
                  onMouseLeave={() => {
                    secondaryHover.current = 'close'
                    setHoverElement('')
                  }}
                >
                  <p
                    id="resultsTableTitle-clientLegalCost"
                    data-textattribute="title-60"
                    className="resultsTableTitleInRow"
                    data-openreplay-obscured
                    style={getStyleFromWhitelabel(
                      props.reportWhitelabel,
                      ReportWhitelabelKeys.sectionDescription,
                      FontLabels.regular,
                      'noRoboto',
                    )}
                  >
                    {resultsSettings.settings.includeIncurredCosts
                      ? getSplitText(
                          getText('title-60', user.settings, partiesFormat),
                          user.settings.language ===
                            AvailableLanguages.norwegian
                            ? 's sakskostnader'
                            : user.settings.language ===
                              AvailableLanguages.swedish
                            ? 's rättegångskostnader'
                            : '’s',
                          39,
                          25,
                        )
                      : getSplitText(
                          getText('title-279', user.settings, partiesFormat),
                          user.settings.language ===
                            AvailableLanguages.norwegian
                            ? 's framtida sakskostnader'
                            : user.settings.language ===
                              AvailableLanguages.swedish
                            ? 's framtida rättegångskostnader'
                            : '’s',
                          39,
                          user.settings.language === AvailableLanguages.english
                            ? 25
                            : 14,
                        )}
                  </p>
                  {hoverElement === 'legalCosts' ? (
                    <div
                      className="hoverComponentContainer"
                      style={{ top: '10px', right: '22px' }}
                    >
                      <HoverComponent
                        hoverText={
                          resultsSettings.settings.includeIncurredCosts
                            ? getText('hover-45', user.settings, partiesFormat)
                            : getText('hover-66', user.settings, partiesFormat)
                        }
                        textAttribute={`${
                          resultsSettings.settings.includeIncurredCosts
                            ? 'hover-45'
                            : 'hover-66'
                        }`}
                        id="legalCosts"
                      />
                    </div>
                  ) : null}
                </div>
                <div
                  className="row row6"
                  onMouseEnter={() => {
                    secondaryHover.current = 'legalCostsCovered'
                    setTimeout(() => {
                      if (secondaryHover.current === 'legalCostsCovered') {
                        setHoverElement('legalCostsCovered')
                      }
                    }, 1000)
                  }}
                  onMouseLeave={() => {
                    secondaryHover.current = 'close'
                    setHoverElement('')
                  }}
                >
                  <p
                    id="resultsTableTitle-legalCostsCovered"
                    data-textattribute="title-61, title-61b, title-61c"
                    className="resultsTableTitleInRow"
                    data-openreplay-obscured
                    style={getStyleFromWhitelabel(
                      props.reportWhitelabel,
                      ReportWhitelabelKeys.sectionDescription,
                      FontLabels.regular,
                      'noRoboto',
                    )}
                  >
                    {getText('title-61', user.settings)}{' '}
                    <span
                      style={getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'noRoboto',
                      )}
                    >
                      {getText('title-61b', user.settings)}
                    </span>{' '}
                    {getText('title-61c', user.settings, partiesFormat).length >
                    (user.settings.language === AvailableLanguages.english
                      ? 26
                      : 11)
                      ? getText('title-61c', user.settings, partiesFormat)
                          .substring(
                            0,
                            user.settings.language ===
                              AvailableLanguages.english
                              ? 26
                              : 11,
                          )
                          .trim() + '...'
                      : getText('title-61c', user.settings, partiesFormat)}
                  </p>
                  {hoverElement === 'legalCostsCovered' ? (
                    <div
                      className="hoverComponentContainer"
                      style={{ top: '10px', right: '-120px' }}
                    >
                      <HoverComponent
                        hoverText={
                          props.resultsSnapshot.court ===
                          TypeOfInstance.Public_Court
                            ? getText('hover-46', user.settings, partiesFormat)
                            : props.resultsSnapshot.court ===
                              TypeOfInstance.Arbitration
                            ? getText('hover-47', user.settings, partiesFormat)
                            : getText('hover-48', user.settings, partiesFormat)
                        }
                        textAttribute={
                          props.resultsSnapshot.court ===
                          TypeOfInstance.Public_Court
                            ? 'hover-46'
                            : props.resultsSnapshot.court ===
                              TypeOfInstance.Arbitration
                            ? 'hover-47'
                            : 'hover-48'
                        }
                        id="legalCostsCovered"
                      />
                    </div>
                  ) : null}
                </div>
                <div
                  className="row row7"
                  onMouseEnter={() => {
                    secondaryHover.current = 'legalCostsPaid'
                    setTimeout(() => {
                      if (secondaryHover.current === 'legalCostsPaid') {
                        setHoverElement('legalCostsPaid')
                      }
                    }, 1000)
                  }}
                  onMouseLeave={() => {
                    secondaryHover.current = 'close'
                    setHoverElement('')
                  }}
                >
                  <p
                    id="resultsTableTitle-legalCostsPaid"
                    data-textattribute="title-62, title-62b, title-62c"
                    className="resultsTableTitleInRow"
                    data-openreplay-obscured
                    style={getStyleFromWhitelabel(
                      props.reportWhitelabel,
                      ReportWhitelabelKeys.sectionDescription,
                      FontLabels.regular,
                      'noRoboto',
                    )}
                  >
                    {getText('title-62', user.settings)}{' '}
                    <span
                      style={getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'noRoboto',
                      )}
                    >
                      {getText('title-62b', user.settings)}
                    </span>{' '}
                    {getText('title-62c', user.settings, partiesFormat).length >
                    (user.settings.language === AvailableLanguages.english
                      ? 26
                      : 11)
                      ? getText('title-62c', user.settings, partiesFormat)
                          .substring(
                            0,
                            user.settings.language ===
                              AvailableLanguages.english
                              ? 26
                              : 11,
                          )
                          .trim() + '...'
                      : getText('title-62c', user.settings, partiesFormat)}
                  </p>
                  {hoverElement === 'legalCostsPaid' ? (
                    <div
                      className="hoverComponentContainer"
                      style={{ top: '10px', right: '-128px' }}
                    >
                      <HoverComponent
                        hoverText={
                          props.resultsSnapshot.court ===
                          TypeOfInstance.Public_Court
                            ? getText('hover-49', user.settings, partiesFormat)
                            : props.resultsSnapshot.court ===
                              TypeOfInstance.Arbitration
                            ? getText('hover-50', user.settings, partiesFormat)
                            : getText('hover-51', user.settings, partiesFormat)
                        }
                        textAttribute={
                          props.resultsSnapshot.court ===
                          TypeOfInstance.Public_Court
                            ? 'hover-49'
                            : props.resultsSnapshot.court ===
                              TypeOfInstance.Arbitration
                            ? 'hover-50'
                            : 'hover-51'
                        }
                        id="legalCostsPaid"
                      />
                    </div>
                  ) : null}
                </div>
                {showInsurance(dataForShowingRows) ||
                props.resultsSnapshot.hasInsurance ? (
                  <div
                    className="row row8"
                    onMouseEnter={() => {
                      secondaryHover.current = 'legalCostsInsurance'
                      setTimeout(() => {
                        if (secondaryHover.current === 'legalCostsInsurance') {
                          setHoverElement('legalCostsInsurance')
                        }
                      }, 1000)
                    }}
                    onMouseLeave={() => {
                      secondaryHover.current = 'close'
                      setHoverElement('')
                    }}
                  >
                    <p
                      id="resultsTableTitle-legalCostsInsurance"
                      data-textattribute="title-63"
                      className="resultsTableTitleInRow"
                      style={getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'noRoboto',
                      )}
                    >
                      {getText('title-63', user.settings)}
                    </p>
                    {hoverElement === 'legalCostsInsurance' ? (
                      <div
                        className="hoverComponentContainer"
                        style={{ top: '10px', right: '-100px' }}
                      >
                        <HoverComponent
                          hoverText={getText(
                            'hover-52',
                            user.settings,
                            partiesFormat,
                          )}
                          textAttribute="hover-52"
                          id="legalCostsInsurance"
                        />
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </>
            ) : null}
            {showOutOfCourt(dataForShowingRows) ? (
              <div
                className="row row9"
                onMouseEnter={() => {
                  secondaryHover.current = 'extrenalConsequence'
                  setTimeout(() => {
                    if (secondaryHover.current === 'extrenalConsequence') {
                      setHoverElement('extrenalConsequence')
                    }
                  }, 1000)
                }}
                onMouseLeave={() => {
                  secondaryHover.current = 'close'
                  setHoverElement('')
                }}
              >
                <p
                  id="resultsTableTitle-outOfCourt"
                  data-textattribute="title-64"
                  className="resultsTableTitleInRow"
                  style={getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.sectionDescription,
                    FontLabels.regular,
                    'noRoboto',
                  )}
                >
                  {getText('title-64', user.settings)}
                </p>
                {hoverElement === 'extrenalConsequence' ? (
                  <div
                    className="hoverComponentContainer"
                    style={{ top: '10px', right: '-50px' }}
                  >
                    <HoverComponent
                      hoverText={getText('hover-53', user.settings)}
                      textAttribute="hover-53"
                      id="extrenalConsequence"
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            {snapshotHasEnforcementRisk(props.resultsSnapshot) &&
            showEnforcement(dataForShowingRows) ? (
              <div
                className="row row10"
                onMouseEnter={() => {
                  secondaryHover.current = 'enforcement'
                  setTimeout(() => {
                    if (secondaryHover.current === 'enforcement') {
                      setHoverElement('enforcement')
                    }
                  }, 1000)
                }}
                onMouseLeave={() => {
                  secondaryHover.current = 'close'
                  setHoverElement('')
                }}
              >
                <p
                  id="resultsTableTitle-enforcement"
                  data-textattribute="title-65"
                  className="resultsTableTitleInRow"
                  style={getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.sectionDescription,
                    FontLabels.regular,
                    'noRoboto',
                  )}
                >
                  {getText('title-65', user.settings)}
                </p>
                {hoverElement === 'enforcement' ? (
                  <div
                    className="hoverComponentContainer"
                    style={{ top: '10px', right: '-50px' }}
                  >
                    <HoverComponent
                      hoverText={
                        props.resultsSnapshot.court ===
                        TypeOfInstance.Public_Court
                          ? getText('hover-54', user.settings)
                          : props.resultsSnapshot.court ===
                            TypeOfInstance.Arbitration
                          ? getText('hover-55', user.settings)
                          : getText('hover-56', user.settings)
                      }
                      textAttribute={
                        props.resultsSnapshot.court ===
                        TypeOfInstance.Public_Court
                          ? 'hover-54'
                          : props.resultsSnapshot.court ===
                            TypeOfInstance.Arbitration
                          ? 'hover-55'
                          : 'hover-56'
                      }
                      id="enforcement"
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            <div className="line"></div>
            <div
              className="row row11"
              onMouseEnter={() => {
                secondaryHover.current = 'financialOutcome'
                setTimeout(() => {
                  if (secondaryHover.current === 'financialOutcome') {
                    setHoverElement('financialOutcome')
                  }
                }, 1000)
              }}
              onMouseLeave={() => {
                secondaryHover.current = 'close'
                setHoverElement('')
              }}
            >
              <p
                id="resultsTableTitle-financialOutcome"
                data-textattribute="title-66"
                className="resultsTableTitleInRow"
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  'noRoboto',
                )}
              >
                {getText('title-66', user.settings)}
              </p>
              {hoverElement === 'financialOutcome' ? (
                <div
                  className="hoverComponentContainer"
                  style={{ top: '10px', right: '-20px' }}
                >
                  <HoverComponent
                    hoverText={getText('hover-57', user.settings)}
                    textAttribute="hover-57"
                    id="financialOutcome"
                  />
                </div>
              ) : null}
            </div>
            <div
              className="row row12"
              onMouseEnter={() => {
                secondaryHover.current = 'weightedProbability'
                setTimeout(() => {
                  if (secondaryHover.current === 'weightedProbability') {
                    setHoverElement('weightedProbability')
                  }
                }, 1000)
              }}
              onMouseLeave={() => {
                secondaryHover.current = 'close'
                setHoverElement('')
              }}
            >
              <p
                id="resultsTableTitle-weightedProbability"
                data-textattribute="title-67"
                className="resultsTableTitleInRow"
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  'noRoboto',
                )}
              >
                {getText('title-67', user.settings)}
              </p>
              {hoverElement === 'weightedProbability' ? (
                <div
                  className="hoverComponentContainer"
                  style={{ top: '10px', right: '-40px' }}
                >
                  <HoverComponent
                    hoverText={getText('hover-58', user.settings)}
                    textAttribute="hover-58"
                    id="weightedProbability"
                  />
                </div>
              ) : null}
            </div>
            <div className="scrollBar"></div>
          </div>
        </div>
        <div className="resultsColumnsContainer maxMinColumnsContainer">
          {sortedDataForTable.map((item, index) => (
            <div
              className="resultsRows"
              key={index}
              id={`resultsRows-${index}`}
            >
              <div className="title title1 resultsTitle">
                <ResultsTooltip
                  open={hoverElement === `${index}OutcomeTitle` ? true : false}
                  title={
                    <OutcomeTitle
                      id={index}
                      sortedDataForTable={sortedDataForTable}
                      currentSnapshot={props.resultsSnapshot}
                      resultsViewParty={props.resultsViewParty}
                    />
                  }
                >
                  <p
                    className="textForHover"
                    onClick={() => setHoverElement(`${index}OutcomeTitle`)}
                    style={getStyleFromWhitelabel(
                      props.reportWhitelabel,
                      ReportWhitelabelKeys.sectionDescription,
                      FontLabels.regular,
                      'noRoboto',
                    )}
                  >
                    {item[0] as string}
                  </p>
                </ResultsTooltip>
              </div>

              <div className="row row1">
                <ResultsTooltip
                  open={hoverElement === `${index}Description` ? true : false}
                  title={
                    <Description
                      id={index}
                      type={item[1] as number}
                      state={props.resultsSnapshot}
                    />
                  }
                >
                  <div
                    className="textForHover"
                    onClick={() => setHoverElement(`${index}Description`)}
                  >
                    {item[1] === 0 ? (
                      <img
                        src={firstTrialImg}
                        alt="firstTrialImg"
                        id={`firstTrialImg-${index}`}
                      />
                    ) : item[1] === 1 ? (
                      <img
                        src={secondTrialImg}
                        alt="secondTrialImg"
                        id={`secondTrialImg-${index}`}
                      />
                    ) : item[1] === 2 ? (
                      <img
                        src={bankruptcyFirstTrialImg}
                        alt="bankruptcyFirstTrialImg"
                        id={`bankruptcyFirstTrialImg-${index}`}
                      />
                    ) : (
                      <img
                        src={bankruptcySecondTrialImg}
                        alt="bankruptcySecondTrialImg"
                        id={`bankruptcySecondTrialImg-${index}`}
                      />
                    )}
                  </div>
                </ResultsTooltip>
              </div>
              <div className="row row2">
                <ResultsTooltip
                  open={
                    hoverElement === `${index}ProbabilityOfOutcome`
                      ? true
                      : false
                  }
                  title={
                    <ProbabilityOfOutcome
                      index={index}
                      sortedDataForTable={sortedDataForTable}
                      currentSnapshot={props.resultsSnapshot}
                      resultsViewParty={props.resultsViewParty}
                    />
                  }
                >
                  <p
                    className="textForHover robotoNumbers"
                    onClick={() =>
                      setHoverElement(`${index}ProbabilityOfOutcome`)
                    }
                    style={getStyleFromWhitelabel(
                      props.reportWhitelabel,
                      ReportWhitelabelKeys.sectionDescription,
                      FontLabels.regular,
                      'roboto',
                    )}
                  >
                    {(item[2] as number) * 100 < 0.01
                      ? '~0 %'
                      : stringAndRoundBigNumbers(
                          (item[2] as number) * 100,
                          user.settings,
                          resultsSettings.settings.roundedResults,
                          false,
                          true,
                        ) + ' %'}
                  </p>
                </ResultsTooltip>
              </div>
              <div className="title title2 resultsTitle"></div>
              <div className="row row3">
                <ResultsTooltip
                  open={
                    hoverElement === `${index}PrincipalAmount` ? true : false
                  }
                  title={
                    <PrincipalAmount
                      id={index}
                      sortedDataForTable={sortedDataForTable}
                      currentSnapshot={props.resultsSnapshot}
                      resultsViewParty={props.resultsViewParty}
                    />
                  }
                >
                  <p
                    className={
                      (item[3] as number) < 0
                        ? 'textForHover negativeInResultsList robotoNumbers'
                        : 'textForHover robotoNumbers'
                    }
                    onClick={() => setHoverElement(`${index}PrincipalAmount`)}
                    id={`${index}PrincipalAmountText`}
                    style={{
                      ...resultsFontSize(
                        stringAndRoundBigNumbers(
                          item[3] as number,
                          user.settings,
                          resultsSettings.settings.roundedResults,
                        ),
                      ),
                      ...getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'roboto',
                      ),
                    }}
                  >
                    {stringAndRoundBigNumbers(
                      item[3] as number,
                      user.settings,
                      resultsSettings.settings.roundedResults,
                    )}
                  </p>
                </ResultsTooltip>
              </div>
              {showInterest(dataForShowingRows) ? (
                <div className="row row4">
                  <ResultsTooltip
                    open={
                      hoverElement === `${index}InterestAwarded` ? true : false
                    }
                    title={
                      <InterestAwarded
                        id={index}
                        sortedDataForTable={sortedDataForTable}
                        currentSnapshot={props.resultsSnapshot}
                        resultsViewParty={props.resultsViewParty}
                      />
                    }
                  >
                    <p
                      className={
                        (item[4] as number) < 0
                          ? 'textForHover negativeInResultsList robotoNumbers'
                          : 'textForHover robotoNumbers'
                      }
                      onClick={() => setHoverElement(`${index}InterestAwarded`)}
                      id={`${index}InterestAwardedText`}
                      style={{
                        ...resultsFontSize(
                          stringAndRoundBigNumbers(
                            item[4] as number,
                            user.settings,
                            resultsSettings.settings.roundedResults,
                          ),
                        ),
                        ...getStyleFromWhitelabel(
                          props.reportWhitelabel,
                          ReportWhitelabelKeys.sectionDescription,
                          FontLabels.regular,
                          'roboto',
                        ),
                      }}
                    >
                      {stringAndRoundBigNumbers(
                        item[4] as number,
                        user.settings,
                        resultsSettings.settings.roundedResults,
                      )}
                    </p>
                  </ResultsTooltip>
                </div>
              ) : null}
              {showLegalFees(dataForShowingRows) ? (
                <>
                  <div className="row row5">
                    <p
                      className={
                        (item[5] as number) < 0
                          ? 'negativeInResultsList robotoNumbers'
                          : 'robotoNumbers'
                      }
                      id={`${index}clientLegalCostsText`}
                      style={{
                        ...resultsFontSize(
                          stringAndRoundBigNumbers(
                            item[5] as number,
                            user.settings,
                            resultsSettings.settings.roundedResults,
                          ),
                        ),
                        ...getStyleFromWhitelabel(
                          props.reportWhitelabel,
                          ReportWhitelabelKeys.sectionDescription,
                          FontLabels.regular,
                          'roboto',
                        ),
                      }}
                    >
                      {stringAndRoundBigNumbers(
                        item[5] as number,
                        user.settings,
                        resultsSettings.settings.roundedResults,
                      )}
                    </p>
                  </div>
                  <div className="row row6">
                    <ResultsTooltip
                      open={
                        hoverElement === `${index}LegalCostsBy` ? true : false
                      }
                      title={
                        <LegalCostsBy
                          id={index}
                          sortedDataForTable={sortedDataForTable}
                          currentSnapshot={props.resultsSnapshot}
                        />
                      }
                    >
                      <p
                        className="textForHover robotoNumbers"
                        onClick={() => setHoverElement(`${index}LegalCostsBy`)}
                        id={`${index}LegalCostsByText`}
                        style={{
                          ...resultsFontSize(
                            stringAndRoundBigNumbers(
                              item[6] as number,
                              user.settings,
                              resultsSettings.settings.roundedResults,
                            ),
                          ),
                          ...getStyleFromWhitelabel(
                            props.reportWhitelabel,
                            ReportWhitelabelKeys.sectionDescription,
                            FontLabels.regular,
                            'roboto',
                          ),
                        }}
                      >
                        {stringAndRoundBigNumbers(
                          item[6] as number,
                          user.settings,
                          resultsSettings.settings.roundedResults,
                        )}
                      </p>
                    </ResultsTooltip>
                  </div>
                  <div className="row row7">
                    <ResultsTooltip
                      open={
                        hoverElement === `${index}LegalCostsTo` ? true : false
                      }
                      title={
                        <LegalCostsTo
                          id={index}
                          sortedDataForTable={sortedDataForTable}
                          currentSnapshot={props.resultsSnapshot}
                        />
                      }
                    >
                      <p
                        className={
                          (item[7] as number) < 0
                            ? 'textForHover negativeInResultsList robotoNumbers'
                            : 'textForHover robotoNumbers'
                        }
                        onClick={() => setHoverElement(`${index}LegalCostsTo`)}
                        id={`${index}LegalCostsToText`}
                        style={{
                          ...resultsFontSize(
                            stringAndRoundBigNumbers(
                              item[7] as number,
                              user.settings,
                              resultsSettings.settings.roundedResults,
                            ),
                          ),
                          ...getStyleFromWhitelabel(
                            props.reportWhitelabel,
                            ReportWhitelabelKeys.sectionDescription,
                            FontLabels.regular,
                            'roboto',
                          ),
                        }}
                      >
                        {stringAndRoundBigNumbers(
                          item[7] as number,
                          user.settings,
                          resultsSettings.settings.roundedResults,
                        )}
                      </p>
                    </ResultsTooltip>
                  </div>
                  {showInsurance(dataForShowingRows) ||
                  props.resultsSnapshot.hasInsurance ? (
                    <div className="row row8">
                      <ResultsTooltip
                        open={
                          hoverElement === `${index}LegalCostsByInsurance`
                            ? true
                            : false
                        }
                        title={
                          <LegalCostsByInsurance
                            id={index}
                            sortedDataForTable={sortedDataForTable}
                            currentSnapshot={props.resultsSnapshot}
                          />
                        }
                      >
                        <p
                          className="textForHover robotoNumbers"
                          onClick={() =>
                            setHoverElement(`${index}LegalCostsByInsurance`)
                          }
                          id={`${index}LegalCostsByInsuranceText`}
                          style={{
                            ...resultsFontSize(
                              stringAndRoundBigNumbers(
                                item[8] as number,
                                user.settings,
                                resultsSettings.settings.roundedResults,
                              ),
                            ),
                            ...getStyleFromWhitelabel(
                              props.reportWhitelabel,
                              ReportWhitelabelKeys.sectionDescription,
                              FontLabels.regular,
                              'roboto',
                            ),
                          }}
                        >
                          {stringAndRoundBigNumbers(
                            item[8] as number,
                            user.settings,
                            resultsSettings.settings.roundedResults,
                          )}
                        </p>
                      </ResultsTooltip>
                    </div>
                  ) : null}
                </>
              ) : null}
              {showOutOfCourt(dataForShowingRows) ? (
                <div className="row row9">
                  <ResultsTooltip
                    open={
                      hoverElement === `${index}ExternalConsequence`
                        ? true
                        : false
                    }
                    title={
                      <ExternalConsequence
                        id={index}
                        sortedDataForTable={sortedDataForTable}
                        currentSnapshot={props.resultsSnapshot}
                      />
                    }
                  >
                    <p
                      className={
                        (item[9] as number) < 0
                          ? 'textForHover negativeInResultsList robotoNumbers'
                          : 'textForHover robotoNumbers'
                      }
                      onClick={() =>
                        setHoverElement(`${index}ExternalConsequence`)
                      }
                      id={`${index}ExternalConsequenceText`}
                      style={{
                        ...resultsFontSize(
                          stringAndRoundBigNumbers(
                            item[9] as number,
                            user.settings,
                            resultsSettings.settings.roundedResults,
                          ),
                        ),
                        ...getStyleFromWhitelabel(
                          props.reportWhitelabel,
                          ReportWhitelabelKeys.sectionDescription,
                          FontLabels.regular,
                          'roboto',
                        ),
                      }}
                    >
                      {stringAndRoundBigNumbers(
                        item[9] as number,
                        user.settings,
                        resultsSettings.settings.roundedResults,
                      )}
                    </p>
                  </ResultsTooltip>
                </div>
              ) : null}
              {snapshotHasEnforcementRisk(props.resultsSnapshot) &&
              showEnforcement(dataForShowingRows) ? (
                <div className="row row10">
                  <ResultsTooltip
                    open={
                      hoverElement === `${index}UnsuccessfulEnforcement`
                        ? item[1] === 0 || item[1] === 1
                          ? false
                          : true
                        : false
                    }
                    title={<UnsuccessfulEnforcement />}
                  >
                    <p
                      className={
                        item[1] === 0 || item[1] === 1
                          ? 'textForHover robotoNumbers'
                          : 'textForHover negativeInResultsList robotoNumbers'
                      }
                      onClick={() =>
                        setHoverElement(`${index}UnsuccessfulEnforcement`)
                      }
                      id={`${index}UnsuccessfulEnforcementText`}
                      style={{
                        ...resultsFontSize(
                          item[1] === 0 || item[1] === 1
                            ? '-'
                            : '-' +
                                stringAndRoundBigNumbers(
                                  item[10] as number,
                                  user.settings,
                                  resultsSettings.settings.roundedResults,
                                ),
                        ),
                        ...getStyleFromWhitelabel(
                          props.reportWhitelabel,
                          ReportWhitelabelKeys.sectionDescription,
                          FontLabels.regular,
                          'roboto',
                        ),
                      }}
                    >
                      {item[1] === 0 || item[1] === 1
                        ? '-'
                        : '-' +
                          stringAndRoundBigNumbers(
                            item[10] as number,
                            user.settings,
                            resultsSettings.settings.roundedResults,
                          )}
                    </p>
                  </ResultsTooltip>
                </div>
              ) : null}
              <div className="line"></div>
              <div className="row row11">
                <p
                  className="robotoNumbersBold"
                  style={{
                    ...resultsFontSize(
                      stringAndRoundBigNumbers(
                        item[11] as number,
                        user.settings,
                        resultsSettings.settings.roundedResults,
                      ) +
                        ' ' +
                        props.resultsSnapshot.currency,
                    ),
                    ...getStyleFromWhitelabel(
                      props.reportWhitelabel,
                      ReportWhitelabelKeys.sectionDescription,
                      FontLabels.bold,
                      'roboto',
                    ),
                  }}
                >
                  {stringAndRoundBigNumbers(
                    item[11] as number,
                    user.settings,
                    resultsSettings.settings.roundedResults,
                  )}{' '}
                  {props.resultsSnapshot.currency}
                </p>
              </div>
              <div className="row row12">
                <ResultsTooltip
                  open={
                    hoverElement === `${index}WeightedProbability`
                      ? true
                      : false
                  }
                  title={<WeightedProbability />}
                >
                  <p
                    className="textForHover robotoNumbers"
                    onClick={() =>
                      setHoverElement(`${index}WeightedProbability`)
                    }
                    id={`${index}WeightedProbabilityText`}
                    style={{
                      ...resultsFontSize(
                        Math.abs(item[12] as number) < 0.01 &&
                          Math.abs(item[12] as number) !== 0
                          ? `~0 ${props.resultsSnapshot.currency}`
                          : stringAndRoundBigNumbers(
                              item[12] as number,
                              user.settings,
                              resultsSettings.settings.roundedResults,
                            ) +
                              ' ' +
                              props.resultsSnapshot.currency,
                      ),
                      ...getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'roboto',
                      ),
                    }}
                  >
                    {Math.abs(item[12] as number) < 0.01 &&
                    Math.abs(item[12] as number) !== 0
                      ? `~0 ${props.resultsSnapshot.currency}`
                      : `${stringAndRoundBigNumbers(
                          item[12] as number,
                          user.settings,
                          resultsSettings.settings.roundedResults,
                        )} ${props.resultsSnapshot.currency}`}
                  </p>
                </ResultsTooltip>
              </div>
              <div className="scrollBar"></div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
