import { useRecoilValue } from 'recoil'
import { InterestViewOption } from '../../../../../../../../models/enums'
import {
  calculateWeightedValues,
  findClaimedAmountOfClaim,
  findCounterClaimedAmountOfClaim,
  findWeightedValueOfClaim,
  roundNumberTo,
} from '../../../../../../../../services/commonFunctions'
import {
  getText,
  translateDate,
} from '../../../../../../../../services/textFunctions'
import { userState } from '../../../../../../../../states/UserState'
import { scenarioSnapshotState } from '../../../../../../../../states/ScenarioSnapshotState'
import {
  getClientAwardedAmountSum,
  getOpposingPartyAwardedAmountSum,
  hasClaimAmount,
  hasCounterClaimAmount,
} from '../../../../../../../../services/claimFunctions'
import { formattedNumToString } from '../../../../../../../../services/formatNum'
import { ReportWhitelabel } from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import { DateTime } from 'luxon'
import { atLeastOneClaimHasInterest } from '../../../../../../../../services/resultsFunctions'

type Props = {
  reportWhitelabel?: ReportWhitelabel
}

export default function ClaimsOverviewComponent(props: Props) {
  const user = useRecoilValue(userState)
  const currentSnapshot = useRecoilValue(scenarioSnapshotState).currentSnapshot

  const partiesFormat = [
    currentSnapshot.partyFormatOwn,
    currentSnapshot.partyFormatOther,
  ]

  const weightedValues = calculateWeightedValues(currentSnapshot)

  return (
    <div className="claimsOverviewContainer" key={'claimsOverview'}>
      <div className="claimsPreviewReportSectionContainer">
        <div className="claimsPreviewMain">
          <div
            className={'claimsPreviewContainerList'}
            id="claimsPreviewContainerReport"
          >
            <div className="listPreviewTableNamesDiv">
              <div className="listPreviewTitleDiv">
                <p
                  className="listPreviewTableNameClickable"
                  id="titleListTitle"
                  data-textattribute="title-222"
                  style={getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.sectionDescription,
                    FontLabels.regular,
                    'noRoboto',
                  )}
                >
                  {getText('title-222', user.settings)}
                </p>
              </div>
              <div className="listPreviewOtherTableNamesContainer">
                <div className="listPreviewOtherTableNamesDiv">
                  <p
                    className="listPreviewTableNameClickable"
                    id="clientListTitle"
                    data-textattribute="title-223"
                    data-openreplay-obscured
                    style={getStyleFromWhitelabel(
                      props.reportWhitelabel,
                      ReportWhitelabelKeys.sectionDescription,
                      FontLabels.regular,
                      'noRoboto',
                    )}
                  >
                    {getText('title-223', user.settings, partiesFormat)}
                  </p>
                </div>
                <div className="listPreviewOtherTableNamesDiv">
                  <p
                    className="listPreviewTableNameClickable"
                    id="opposingListTitle"
                    data-textattribute="title-224"
                    data-openreplay-obscured
                    style={getStyleFromWhitelabel(
                      props.reportWhitelabel,
                      ReportWhitelabelKeys.sectionDescription,
                      FontLabels.regular,
                      'noRoboto',
                    )}
                  >
                    {getText('title-224', user.settings, partiesFormat)}
                  </p>
                </div>
                <div className="listPreviewOtherTableNamesDiv">
                  <p
                    className="listPreviewTableNameClickable"
                    id="weightedListTitle"
                    data-textattribute="title-225"
                    style={getStyleFromWhitelabel(
                      props.reportWhitelabel,
                      ReportWhitelabelKeys.sectionDescription,
                      FontLabels.regular,
                      'noRoboto',
                    )}
                  >
                    {getText('title-225', user.settings)}
                  </p>
                </div>
              </div>
            </div>
            {currentSnapshot.claims.map((claim, index) => (
              <div
                className="claimsOverviewListContainer"
                key={`list-${index}`}
                style={
                  index % 2 !== 0
                    ? { backgroundColor: 'white' }
                    : { backgroundColor: '#E9E9E9' }
                }
              >
                <div className="listTitleDiv" id={`listeTitleDev-${index}`}>
                  <p
                    className="listTitleText"
                    style={getStyleFromWhitelabel(
                      props.reportWhitelabel,
                      ReportWhitelabelKeys.sectionDescription,
                      FontLabels.regular,
                      'noRoboto',
                    )}
                  >
                    {claim.name}
                  </p>
                </div>
                <div className="listPreviewOtherTableNamesDiv">
                  {hasClaimAmount(claim) ? (
                    <p
                      className={`listTitleRightText robotoNumbers`}
                      id={`listTitlClientText-${index}`}
                      style={getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'roboto',
                        false,
                        false,
                      )}
                    >
                      {formattedNumToString(
                        roundNumberTo(
                          findClaimedAmountOfClaim(currentSnapshot, index),
                          2,
                        ),
                        user.settings,
                      )}{' '}
                      {currentSnapshot.currency}
                    </p>
                  ) : (
                    <p
                      className={`listTitleRightText`}
                      id={`listTitlClientText-${index}`}
                      style={getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'noRoboto',
                        false,
                        false,
                      )}
                    >
                      -
                    </p>
                  )}
                </div>
                <div className="listPreviewOtherTableNamesDiv">
                  {hasCounterClaimAmount(claim) ? (
                    <p
                      className={`listTitleRightText robotoNumbers`}
                      id={`listTitlOpposingPartyText-${index}`}
                      style={getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'roboto',
                        false,
                        false,
                      )}
                    >
                      {formattedNumToString(
                        Math.abs(
                          roundNumberTo(
                            findCounterClaimedAmountOfClaim(
                              currentSnapshot,
                              index,
                            ),
                            2,
                          ),
                        ),
                        user.settings,
                      )}{' '}
                      {currentSnapshot.currency}
                    </p>
                  ) : (
                    <p
                      className={`listTitleRightText`}
                      id={`listTitlOpposingPartyText-${index}`}
                      style={getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'noRoboto',
                        false,
                        false,
                      )}
                    >
                      -
                    </p>
                  )}
                </div>
                <div className="listPreviewOtherTableNamesDiv">
                  <p
                    className={`listTitleRightText robotoNumbers`}
                    id={`weightedValueText-${index}`}
                    style={getStyleFromWhitelabel(
                      props.reportWhitelabel,
                      ReportWhitelabelKeys.sectionDescription,
                      FontLabels.regular,
                      'roboto',
                      false,
                      false,
                    )}
                  >
                    {formattedNumToString(
                      roundNumberTo(
                        findWeightedValueOfClaim(currentSnapshot, index),
                        2,
                      ),
                      user.settings,
                    )}{' '}
                    {currentSnapshot.currency}
                  </p>
                </div>
              </div>
            ))}

            <div className={`listPreviewTableNamesDiv`}>
              <div className={`listPreviewTitleDiv forSum`}>
                <p
                  className="listPreviewTableName forSum"
                  id="listPreviewTableName"
                  data-textattribute={'title-226'}
                  style={getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.sectionDescription,
                    FontLabels.regular,
                    'noRoboto',
                  )}
                >
                  {getText('title-226', user.settings)}
                </p>
              </div>
              <div className="listPreviewOtherTableNamesContainer">
                <>
                  <div
                    className="listPreviewOtherTableNamesDiv"
                    data-openreplay-obscured
                  >
                    <p
                      className="listPreviewTableName forSum noMargin selectable robotoNumbersBold"
                      id="clientAmountValue"
                      style={getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.bold,
                        'roboto',
                        false,
                        false,
                      )}
                    >
                      {getClientAwardedAmountSum(
                        currentSnapshot,
                        user.settings,
                      )}{' '}
                      {currentSnapshot.currency}
                    </p>
                  </div>
                  <div
                    className="listPreviewOtherTableNamesDiv"
                    data-openreplay-obscured
                  >
                    <p
                      className="listPreviewTableName forSum noMargin selectable robotoNumbersBold"
                      id="opposingAmountValue"
                      style={getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.bold,
                        'roboto',
                        false,
                        false,
                      )}
                    >
                      {getOpposingPartyAwardedAmountSum(
                        currentSnapshot,
                        user.settings,
                      )}{' '}
                      {currentSnapshot.currency}
                    </p>
                  </div>
                  <div
                    className="listPreviewOtherTableNamesDiv"
                    data-openreplay-obscured
                  >
                    <p
                      className={`listPreviewTableName forSum noMargin selectable robotoNumbersBold`}
                      id="weightedAmountValue"
                      style={getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.bold,
                        'roboto',
                        false,
                        false,
                      )}
                    >
                      {formattedNumToString(
                        roundNumberTo(weightedValues, 2),
                        user.settings,
                      )}{' '}
                      {currentSnapshot.currency}
                    </p>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="weigthedValueInfoMessageContainer">
        {currentSnapshot.interestViewOption !==
        InterestViewOption.noInterest ? (
          <p
            className="weightedValueInfoMessageText"
            data-textattribute="description-233"
            id="weightedValueInfoMessageText-1"
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
            )}
          >
            {getText('description-233', user.settings)}{' '}
            {translateDate(
              DateTime.fromISO(
                (currentSnapshot.interestViewOption ===
                InterestViewOption.interest1st
                  ? currentSnapshot.firstTrialDate
                  : currentSnapshot.secondTrialDate) as string,
              ).toFormat('dd LLL yyyy'),
              user.settings.language,
            )}
            .
          </p>
        ) : atLeastOneClaimHasInterest(currentSnapshot) ? (
          <p
            className="weightedValueInfoMessageText"
            data-textattribute="description-188"
            id="weightedValueInfoMessageText-1"
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
            )}
          >
            {getText('description-234', user.settings)}.
          </p>
        ) : null}
        <p
          className="weightedValueInfoMessageText"
          data-textattribute="description-188"
          id="weightedValueInfoMessageText-2"
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'noRoboto',
          )}
        >
          {/* newText */}
          {/* Change to include party name */}
          {getText('description-188', user.settings)}
        </p>
      </div>
    </div>
  )
}
