import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  AvailableLanguages,
  SaveMenuEnum,
  SideMenuPages,
} from '../../../models/enums'
import { onlineState } from '../../../states/OnlineState'
import { pageDepthState } from '../../../states/PageDepthState'
import { getText } from '../../../services/textFunctions'
import { userState } from '../../../states/UserState'
import { recoveryModeState } from '../../../states/RecoveryModeState'
import { freemiumState } from '../../../states/FreemiumState'
import { logActivity } from '../../../services/commonFunctions'

type Props = {
  title: SideMenuPages
  position: string
  bottomActive?: boolean
  topActive?: boolean
  thumbnailActive: string
  thumbnailHover: string
  thumbnailInactive: string
  isSideMenuTitleActive: boolean
  saved: boolean
  reportSaved: boolean
  showHide: boolean
  saveMenuOn?: undefined | string
  setSaveMenuOn?: (param: undefined | string) => void
}

export default function SideMenuTitle(props: Props) {
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)
  const recoveryMode = useRecoilValue(recoveryModeState)

  const navigate = useNavigate()
  const [hover, setHover] = useState(false)
  const [pageDepth, setPageDepth] = useRecoilState(pageDepthState)
  const [online, setOnline] = useRecoilState(onlineState)

  function navigateTo(title: string) {
    if (
      pageDepth < 3 ||
      (pageDepth === 3 &&
        (props.saved || recoveryMode.recoveryPreview !== 'none')) ||
      (pageDepth === 3.1 && props.reportSaved)
    ) {
      if (online.networkOn) {
        if (title === SideMenuPages.MyCases) {
          //Mixpanel 85 (All)
          logActivity(freemium.isFreemium, 'Accessed My Cases through sidemenu')
          navigate('/')
        }
        if (title === SideMenuPages.Academy) {
          setPageDepth(0.9)
          navigate('/academy')
        }
        if (title === SideMenuPages.Settings) {
          setPageDepth(-1)
          navigate('/settings')
        }
      } else {
        setOnline({ ...online, shaking: true })
      }
    } else if (props.setSaveMenuOn) {
      if (title === SideMenuPages.MyCases) {
        //Mixpanel 85 (All)
        logActivity(freemium.isFreemium, 'Accessed My Cases through sidemenu')
        props.setSaveMenuOn!(SaveMenuEnum.mycasesSide)
      } else if (title === SideMenuPages.Academy) {
        props.setSaveMenuOn!(SaveMenuEnum.academy)
      } else if (title === SideMenuPages.Settings) {
        props.setSaveMenuOn!(SaveMenuEnum.settings)
      }
    }
  }

  function getNavLink() {
    switch (props.title) {
      case SideMenuPages.MyCases:
        return '/'
      case SideMenuPages.Academy:
        return '/academy'
      case SideMenuPages.Settings:
        return '/settings'
      default:
        return '/'
    }
  }

  return (
    <Link
      to={getNavLink()}
      className={`sideMenuTitleContainer 
        ${props.showHide ? 'sideMenuTitleContainerOpen' : null} 
        ${
          props.isSideMenuTitleActive ? null : 'inactiveSideMenuTitleContainer'
        } 
        ${props.position}
        ${props.bottomActive ? 'bottomActive' : null}
        ${props.topActive ? 'topActive' : null}
        `}
      onClick={(e) => {
        if (!e.ctrlKey && !e.metaKey) {
          e.preventDefault()
          navigateTo(props.title)
        }
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="sideMenuTitleThumbnailContainer">
        <img
          src={
            props.isSideMenuTitleActive
              ? props.thumbnailActive
              : hover
              ? props.thumbnailHover
              : props.thumbnailInactive
          }
          alt="sideMenuTitleThumbnail"
          className="sideMenuTitleThumbnail"
        />
      </div>

      {props.showHide ? (
        <p
          className={
            props.isSideMenuTitleActive
              ? 'sideMenuTitleText'
              : hover
              ? 'sideMenuTitleText hoverSideMenuTitleText'
              : ' sideMenuTitleText inactiveSideMenuTitleText'
          }
          id={`sideMenuTitleText-${props.title}`}
          data-textattribute={
            props.title === SideMenuPages.MyCases
              ? 'title-1'
              : props.title === SideMenuPages.Academy
              ? 'title-2'
              : 'title-3'
          }
          style={
            props.title === SideMenuPages.Settings &&
            user.settings.language === AvailableLanguages.swedish
              ? { fontSize: '16px' }
              : {}
          }
        >
          {props.title === SideMenuPages.MyCases
            ? getText('title-1', user.settings)
            : props.title === SideMenuPages.Academy
            ? getText('title-2', user.settings)
            : getText('title-3', user.settings)}
        </p>
      ) : null}
    </Link>
  )
}
